
import {defineComponent} from "vue";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import ProductSelectionBaseItem from "@/components/layouts/catalog/product-selection/ProductSelectionBaseItem.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {CatalogSettingsRestService} from "@/services/rest/cpc-mgmt/CatalogSettingsRestService";
import {ProductImportSettingsRestDto} from "@/api/pharma-cpc-mgmt/models";
import {AbilityContext} from "@/context/AbilityContext";
import {default as Modal} from "bootstrap/js/dist/modal";
import ProductSelectionBaseSyncHistory
  from "@/components/layouts/catalog/product-selection/ProductSelectionBaseSyncHistory.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";

export default defineComponent({
  name: "ProductSelectionBase",
  computed: {
    AbilityContext() {
      return AbilityContext
    }
  },
  emits: ["savedSuccessfully"],
  components: {
    InputCheckboxBoolean,
    ProductSelectionBaseSyncHistory,
    AlertError2, BaseSpinner, ProductSelectionBaseItem, BaseCardBody, BaseCardHeader, BaseCard
  },
  data: () => ({
    productSelectionBase: {} as ProductImportSettingsRestDto | any,
    productSelectionBaseUI: UIStateDto.createWithDefaults(),

    productSelectionBaseIncludeWholesaleFebelco: false as boolean,

    synchronisationHistoryModal: null as Modal | null,
  }),
  mounted() {
    // console.log("PHARMACY_SW_SYNC", AbilityContext.isAuthorizedForFeature('PHARMACY_SW_SYNC'))
    // console.log("PHARMACY_SW_SYNC_SETTINGS", AbilityContext.isAuthorizedForFeature('PHARMACY_SW_SYNC_SETTINGS'))
    // console.log("PHARMACY_SW_SYNC_MONITORING", AbilityContext.isAuthorizedForFeature('PHARMACY_SW_SYNC_MONITORING'))
    this.reloadContent();

    if (AbilityContext.isAuthorizedForFeature('PHARMACY_SW_SYNC_MONITORING')) {
      this.synchronisationHistoryModal = new Modal('#synchronisation-history-modal');
    }
  },
  unmounted() {
    this.synchronisationHistoryModal?.dispose();
  },
  methods: {
    handleExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productSelectionBaseUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.productSelectionBaseUI as UIStateDto))
        .catch(exceptions, true);
    },
    onProductSelectionBaseChanged(itemId: string, value: boolean): void {
      this.productSelectionBase[itemId] = value;
    },
    async reloadContent(): Promise<void> {
      this.productSelectionBaseUI.setNotReady();

      try {
        this.productSelectionBase = await CatalogSettingsRestService.getInstance()
          .findProductSelectionBase();

        if (this.productSelectionBase.include_wholesaler_febelco_ol === true
          || this.productSelectionBase.include_wholesaler_febelco_zo === true) {
          this.productSelectionBaseIncludeWholesaleFebelco = true;
        }
      } catch (exceptions: unknown) {
        this.handleExceptions(exceptions);
      } finally {
        this.productSelectionBaseUI.setReady();
      }
    },
    async submitData(): Promise<void> {
      this.productSelectionBaseUI
        .clearError()
        .setNotReady();

      try {
        if (this.productSelectionBaseIncludeWholesaleFebelco) {
          this.productSelectionBase.include_wholesaler_febelco_ol = true;
          this.productSelectionBase.include_wholesaler_febelco_zo = true;
        } else {
          this.productSelectionBase.include_wholesaler_febelco_ol = false;
          this.productSelectionBase.include_wholesaler_febelco_zo = false;
        }

        await CatalogSettingsRestService.getInstance()
          .updateProductSelectionBase(this.productSelectionBase);

        this.$emit('savedSuccessfully');
        await this.reloadContent();
      } catch (exceptions: unknown) {
        this.handleExceptions(exceptions);
      } finally {
        this.productSelectionBaseUI.setReady();
      }
    }
  },
});
