import {BusinessService} from "@/services/business/BusinessService";
import {ProductFeatureRestService} from "@/services/rest/license-license-info/ProductFeatureRestService";
import {ProductFeatureAuthorizationRestDtoModel} from "@/models/ProductFeatureAuthorizationRestDtoModel";
import {AbilityContext} from "@/context/AbilityContext";

export class AbilityContextService extends BusinessService {

    public async updateAbilityContext(): Promise<void> {
        const dto: ProductFeatureAuthorizationRestDtoModel[] = await ProductFeatureRestService.getInstance()
            .findProductFeatureAuthorization();

        AbilityContext.setProductFeatureAuthorizations(dto);
    }
}
