/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog Products
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { PagedProductOverviewRestDto } from '../models';
import { ProductDetailRestDto } from '../models';
/**
 * ProductAPIApi - axios parameter creator
 * @export
 */
export const ProductAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the products.
         * @summary REST.API.CPC.PRD.01 Find products
         * @param {string} customer_code Identifies the product catalog that is beïng accessed.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {boolean} [include_paging_metadata] If false, the paging_metadata will be left out from the response.
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {string} [filter_category_code] A single product category code used as search criterium indicating all products of this category AND its subcategories are expected.
         * @param {string} [filter_brand_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
         * @param {string} [filter_labo_code] A &#x27;|&#x27; separated list of product labo codes used as search criterium.
         * @param {string} [filter_trademark_code] A &#x27;|&#x27; separated list of product trademark codes used as search criterium.
         * @param {string} [filter_product_filter_code] A product filter code used as search criterium indicating all products that correspond to the specified product filter are expected.
         * @param {string} [filter_catalog_subset_product_filter_code] A product filter code used to define a subset of the catalog to work with (for example a subset of all products and categories for healthcare professionals.)
         * @param {string} [search_keyword] A keyword used as search criterium
         * @param {boolean} [filter_user_favorites] When true, the products are filtered based on this authenticated user&#x27;s favorites.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProducts: async (customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, include_paging_metadata?: boolean|null, page?: number|null, page_size?: number|null, filter_category_code?: string|null, filter_brand_code?: string|null, filter_labo_code?: string|null, filter_trademark_code?: string|null, filter_product_filter_code?: string|null, filter_catalog_subset_product_filter_code?: string|null, search_keyword?: string|null, filter_user_favorites?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling findProducts.');
            }
            const localVarPath = `/product/v1/catalog/{customer_code}/product`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (!!include_paging_metadata || typeof(include_paging_metadata) === "boolean") {
                localVarQueryParameter['include_paging_metadata'] = include_paging_metadata;
            }

            if (!!page || typeof(page) === "boolean") {
                localVarQueryParameter['page'] = page;
            }

            if (!!page_size || typeof(page_size) === "boolean") {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (!!filter_category_code || typeof(filter_category_code) === "boolean") {
                localVarQueryParameter['filter_category_code'] = filter_category_code;
            }

            if (!!filter_brand_code || typeof(filter_brand_code) === "boolean") {
                localVarQueryParameter['filter_brand_code'] = filter_brand_code;
            }

            if (!!filter_labo_code || typeof(filter_labo_code) === "boolean") {
                localVarQueryParameter['filter_labo_code'] = filter_labo_code;
            }

            if (!!filter_trademark_code || typeof(filter_trademark_code) === "boolean") {
                localVarQueryParameter['filter_trademark_code'] = filter_trademark_code;
            }

            if (!!filter_product_filter_code || typeof(filter_product_filter_code) === "boolean") {
                localVarQueryParameter['filter_product_filter_code'] = filter_product_filter_code;
            }

            if (!!filter_catalog_subset_product_filter_code || typeof(filter_catalog_subset_product_filter_code) === "boolean") {
                localVarQueryParameter['filter_catalog_subset_product_filter_code'] = filter_catalog_subset_product_filter_code;
            }

            if (!!search_keyword || typeof(search_keyword) === "boolean") {
                localVarQueryParameter['search_keyword'] = search_keyword;
            }

            if (!!filter_user_favorites || typeof(filter_user_favorites) === "boolean") {
                localVarQueryParameter['filter_user_favorites'] = filter_user_favorites;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the product details.
         * @summary REST.API.CPC.PRD.02 Retrieve product details
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} customer_code Identifies the product catalog that is beïng accessed.
         * @param {string} product_code The product code that uniquely identifies the product.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductForProductCode: async (x_dp_customer_code: string, customer_code: string, product_code: string, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling getProductForProductCode.');
            }
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling getProductForProductCode.');
            }
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling getProductForProductCode.');
            }
            const localVarPath = `/product/v1/catalog/{customer_code}/product/{product_code}`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)))
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the product details.
         * @summary REST.API.CPC.PRD.03 Retrieve product details via alias
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} customer_code Identifies the product catalog that is being accessed.
         * @param {number} product_code_alias The numeric alias of the product code that uniquely identifies the product.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductForProductCodeAlias: async (x_dp_customer_code: string, customer_code: string, product_code_alias: number, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling getProductForProductCodeAlias.');
            }
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling getProductForProductCodeAlias.');
            }
            // verify required parameter 'product_code_alias' is not null or undefined
            if (product_code_alias === null || product_code_alias === undefined) {
                throw new RequiredError('product_code_alias','Required parameter product_code_alias was null or undefined when calling getProductForProductCodeAlias.');
            }
            const localVarPath = `/product/v1/catalog/{customer_code}/product/via_alias/{product_code_alias}`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)))
                .replace(`{${"product_code_alias"}}`, encodeURIComponent(String(product_code_alias)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductAPIApi - functional programming interface
 * @export
 */
export const ProductAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the products.
         * @summary REST.API.CPC.PRD.01 Find products
         * @param {string} customer_code Identifies the product catalog that is beïng accessed.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {boolean} [include_paging_metadata] If false, the paging_metadata will be left out from the response.
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {string} [filter_category_code] A single product category code used as search criterium indicating all products of this category AND its subcategories are expected.
         * @param {string} [filter_brand_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
         * @param {string} [filter_labo_code] A &#x27;|&#x27; separated list of product labo codes used as search criterium.
         * @param {string} [filter_trademark_code] A &#x27;|&#x27; separated list of product trademark codes used as search criterium.
         * @param {string} [filter_product_filter_code] A product filter code used as search criterium indicating all products that correspond to the specified product filter are expected.
         * @param {string} [filter_catalog_subset_product_filter_code] A product filter code used to define a subset of the catalog to work with (for example a subset of all products and categories for healthcare professionals.)
         * @param {string} [search_keyword] A keyword used as search criterium
         * @param {boolean} [filter_user_favorites] When true, the products are filtered based on this authenticated user&#x27;s favorites.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProducts(_axios: AxiosInstance, customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, include_paging_metadata?: boolean|null, page?: number|null, page_size?: number|null, filter_category_code?: string|null, filter_brand_code?: string|null, filter_labo_code?: string|null, filter_trademark_code?: string|null, filter_product_filter_code?: string|null, filter_catalog_subset_product_filter_code?: string|null, search_keyword?: string|null, filter_user_favorites?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedProductOverviewRestDto>> {
            const localVarAxiosArgs = await ProductAPIApiAxiosParamCreator(configuration).findProducts(customer_code, x_dp_customer_code, x_dp_language, include_paging_metadata, page, page_size, filter_category_code, filter_brand_code, filter_labo_code, filter_trademark_code, filter_product_filter_code, filter_catalog_subset_product_filter_code, search_keyword, filter_user_favorites, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the product details.
         * @summary REST.API.CPC.PRD.02 Retrieve product details
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} customer_code Identifies the product catalog that is beïng accessed.
         * @param {string} product_code The product code that uniquely identifies the product.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductForProductCode(_axios: AxiosInstance, x_dp_customer_code: string, customer_code: string, product_code: string, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailRestDto>> {
            const localVarAxiosArgs = await ProductAPIApiAxiosParamCreator(configuration).getProductForProductCode(x_dp_customer_code, customer_code, product_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the product details.
         * @summary REST.API.CPC.PRD.03 Retrieve product details via alias
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} customer_code Identifies the product catalog that is being accessed.
         * @param {number} product_code_alias The numeric alias of the product code that uniquely identifies the product.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductForProductCodeAlias(_axios: AxiosInstance, x_dp_customer_code: string, customer_code: string, product_code_alias: number, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailRestDto>> {
            const localVarAxiosArgs = await ProductAPIApiAxiosParamCreator(configuration).getProductForProductCodeAlias(x_dp_customer_code, customer_code, product_code_alias, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductAPIApi - object-oriented interface
 * @export
 * @class ProductAPIApi
 * @extends {BaseAPI}
 */
export class ProductAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for retrieving the products.
     * @summary REST.API.CPC.PRD.01 Find products
     * @param {string} customer_code Identifies the product catalog that is beïng accessed.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {boolean} [include_paging_metadata] If false, the paging_metadata will be left out from the response.
     * @param {number} [page] Specifies the page number that is expected of the paged result.
     * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
     * @param {string} [filter_category_code] A single product category code used as search criterium indicating all products of this category AND its subcategories are expected.
     * @param {string} [filter_brand_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
     * @param {string} [filter_labo_code] A &#x27;|&#x27; separated list of product labo codes used as search criterium.
     * @param {string} [filter_trademark_code] A &#x27;|&#x27; separated list of product trademark codes used as search criterium.
     * @param {string} [filter_product_filter_code] A product filter code used as search criterium indicating all products that correspond to the specified product filter are expected.
     * @param {string} [filter_catalog_subset_product_filter_code] A product filter code used to define a subset of the catalog to work with (for example a subset of all products and categories for healthcare professionals.)
     * @param {string} [search_keyword] A keyword used as search criterium
     * @param {boolean} [filter_user_favorites] When true, the products are filtered based on this authenticated user&#x27;s favorites.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAPIApi
     */
     public findProducts(customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, include_paging_metadata?: boolean|null, page?: number|null, page_size?: number|null, filter_category_code?: string|null, filter_brand_code?: string|null, filter_labo_code?: string|null, filter_trademark_code?: string|null, filter_product_filter_code?: string|null, filter_catalog_subset_product_filter_code?: string|null, search_keyword?: string|null, filter_user_favorites?: boolean|null, options?: any) {
        return ProductAPIApiFp(this.configuration).findProducts(this.axios, customer_code, x_dp_customer_code, x_dp_language, include_paging_metadata, page, page_size, filter_category_code, filter_brand_code, filter_labo_code, filter_trademark_code, filter_product_filter_code, filter_catalog_subset_product_filter_code, search_keyword, filter_user_favorites, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the product details.
     * @summary REST.API.CPC.PRD.02 Retrieve product details
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} customer_code Identifies the product catalog that is beïng accessed.
     * @param {string} product_code The product code that uniquely identifies the product.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAPIApi
     */
     public getProductForProductCode(x_dp_customer_code: string, customer_code: string, product_code: string, x_dp_language?: string|null, options?: any) {
        return ProductAPIApiFp(this.configuration).getProductForProductCode(this.axios, x_dp_customer_code, customer_code, product_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the product details.
     * @summary REST.API.CPC.PRD.03 Retrieve product details via alias
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} customer_code Identifies the product catalog that is being accessed.
     * @param {number} product_code_alias The numeric alias of the product code that uniquely identifies the product.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAPIApi
     */
     public getProductForProductCodeAlias(x_dp_customer_code: string, customer_code: string, product_code_alias: number, x_dp_language?: string|null, options?: any) {
        return ProductAPIApiFp(this.configuration).getProductForProductCodeAlias(this.axios, x_dp_customer_code, customer_code, product_code_alias, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

}
