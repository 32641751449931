import {
    CpcProductEndpointsApiService,
    CpcProductBrandEndpointsApiService, CpcProductTrademarkEndpointsApiService
} from "@/main";
import {
    CodeDescriptionRestDto,
    PagedProductOverviewRestDto
} from "@/api/pharma-cpc-product/models";
import {CustomerContext} from "@/context/CustomerContext";

export default {
    namespaced: true,
    state() {
        return {
            products: {} as PagedProductOverviewRestDto,
            product_brands: [] as CodeDescriptionRestDto[],
            product_trademarks: [] as CodeDescriptionRestDto[]
        }
    },
    actions: {
        clearProductBrands(context: any) {
            context.commit('clearProductBrands');
        },
        clearProductTrademarks(context: any) {
            context.commit('clearProductTrademarks');
        },
        async searchProducts(context: any, payload?: any) {
            let customer_code = payload.customer_code ?? CustomerContext.getCustomerCode() as string;
            if (payload && 'customer_code' in payload) {
                customer_code = payload.customer_code;
            }
            const language = payload.language as string | undefined;
            const include_paging_metadata = payload.include_paging_metadata as boolean | undefined;
            const page = payload.page as number | undefined;
            const page_size = payload.page_size as number | undefined;
            const search_keyword = payload.search_keyword as string | undefined;
            const filter_category_code = payload.filter_category_code as string | undefined;
            const filter_brand_code = payload.filter_brand_code as string | undefined;
            const filter_trademark_code = payload.filter_trademark_code as string | undefined;
            const filter_product_filter_code = payload.filter_product_filter_code as string | undefined;
            const filter_user_favorites = payload.filter_user_favorites as boolean | undefined;


            const response = await CpcProductEndpointsApiService.findProducts(customer_code, undefined, language, include_paging_metadata, page, page_size, filter_category_code, filter_brand_code, null, filter_trademark_code, filter_product_filter_code, null, search_keyword, filter_user_favorites);

            context.commit("setProducts", response.data);

        },
        async searchProductBrands(context: any, payload?: any) {
            let customer_code = payload.customer_code ?? CustomerContext.getCustomerCode() as string;
            if (payload && 'customer_code' in payload) {
                customer_code = payload.customer_code;
            }
            let search_name = undefined as string | undefined;
            if (payload && 'search_name' in payload) {
                search_name = payload.search_name;
            }
            const language = payload.language as string | undefined;

            const response = await CpcProductBrandEndpointsApiService.findProductBrands(customer_code, undefined, language, search_name);

            context.commit("setProductBrands", response.data);
        },
        async searchProductTrademarks(context: any, payload?: any) {
            let customer_code = payload.customer_code ?? CustomerContext.getCustomerCode() as string;
            if (payload && 'customer_code' in payload) {
                customer_code = payload.customer_code;
            }
            let search_name = undefined as string | undefined;
            if (payload && 'search_name' in payload) {
                search_name = payload.search_name;
            }
            const language = payload.language as string | undefined;

            const response = await CpcProductTrademarkEndpointsApiService.findProductTrademarks(customer_code, undefined, language, search_name);

            context.commit("setProductTrademarks", response.data);
        }
    },
    mutations: {
        clearProductBrands(state: any) {
            state.product_brands = [];
        },
        clearProductTrademarks(state: any) {
            state.product_trademarks = [];
        },
        setProducts(state: any, products: PagedProductOverviewRestDto) {
            state.products = products;
        },
        setProductBrands(state: any, product_brands: CodeDescriptionRestDto[]) {
            state.product_brands = product_brands;
        },
        setProductTrademarks(state: any, product_trademarks: CodeDescriptionRestDto[]) {
            state.product_trademarks = product_trademarks;
        },
    },
    getters: {
        getProducts(state: any): PagedProductOverviewRestDto {
            return state.products;
        },
        getProductBrands(state: any): CodeDescriptionRestDto[] {
            return state.product_brands;
        },
        getProductTrademarks(state: any): CodeDescriptionRestDto[] {
            return state.product_trademarks;
        }
    }
}
