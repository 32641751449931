import {RestService} from "@/services/rest/RestService";
import {MenuEndpointsApi} from "@/api/pharma-cms-menu";
import {
    FullMenuItemRestDto,
    FullMenuRestDto,
    MenuItemCreateRestDto,
    MenuItemRestDto, MenuRestDto,
    RelativeUrlRestDto
} from "@/api/pharma-cms-menu/models";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import {MenuItemRestDtoModel} from "@/models/api/pharma-cms-menu/MenuItemRestDtoModel";
import {generateUUIDv4, stringHasContent} from "@/helpers/functions/string";
import {ReferenceTypeEnum} from "@/api/enums/reference-type-enum";
import {hasExternalPageReference, hasInternalPageReference} from "@/helpers/functions/menu";
import {Exception} from "@/api/interfaces";
import {DpExceptions} from "@/error/DpExceptions";
import {PageReferenceRestDtoModel} from "@/models/api/pharma-cms-menu/PageReferenceRestDtoModel";
import {ContentPageReferenceRestDtoModel} from "@/models/api/pharma-cms-menu/ContentPageReferenceRestDtoModel";
import {TargetEnum} from "@/api/enums/target-enum";

export class MenuRestService extends RestService {

    private apiService: MenuEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new MenuEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async createMenuItem(menu_item: MenuItemRestDto, menu_id: string, has_sub_menu: boolean): Promise<void> {
        const created_menu_item = parseCreatedMenuItem(menu_item, has_sub_menu) as MenuItemCreateRestDto;

        await this.apiService.createMenuItem(created_menu_item, menu_id);
    }

    public async deleteMenuItem(menu_item_id: string): Promise<void> {
        await this.apiService.deleteMenuItem(menu_item_id);
    }

    public async findMenuById(menu_id: string): Promise<MenuItemRestDtoModel> {
        const response = await this.apiService.findMenuById(menu_id);

        return response.data;
    }

    public async findMenuBySlot(menu_slot_code: string): Promise<FullMenuRestDto> {
        const response = await this.apiService.findMenuBySlot(menu_slot_code);

        return response.data;
    }

    public async findMenuItemsById(menu_id: string): Promise<FullMenuItemRestDto[]> {
        const response = await this.apiService.getMenuItemsById(menu_id);

        return response.data.results ?? [];
    }

    public async findRelativeUrls(): Promise<RelativeUrlRestDto[]> {
        const response = await this.apiService.findRelativeUrls();

        return response.data;
    }

    public async moveMenuItem(menu_item_id: string, move_action: MoveActionEnum): Promise<void> {
        switch (move_action) {
            case MoveActionEnum.TOP:
                await this.apiService.moveMenuItemToTop(menu_item_id);
                break;
            case MoveActionEnum.BOTTOM:
                await this.apiService.moveMenuItemToBottom(menu_item_id);
                break;
            case MoveActionEnum.UP:
                await this.apiService.moveMenuItemUp(menu_item_id);
                break;
            case MoveActionEnum.DOWN:
                await this.apiService.moveMenuItemDown(menu_item_id);
                break;
            default:
                throw new Error('Unsupported move_action to call ' + move_action);
        }
    }

    public async updateMenu(menu: MenuRestDto, menu_id: string): Promise<void> {
        await this.apiService.updateMenu(menu, menu_id);
    }

    public async updateMenuItem(menu_item: MenuItemRestDto, menu_item_id: string): Promise<void> {
        await this.apiService.updateMenuItem(parseSavedMenuItem(menu_item), menu_item_id);
    }
}


function parseCreatedMenuItem(menu_item: MenuItemRestDto, has_sub_menu: boolean): MenuItemCreateRestDto {
    const parsed_menu_item = JSON.parse(JSON.stringify(menu_item)) as MenuItemRestDto;

    if (has_sub_menu && !stringHasContent(parsed_menu_item.submenu_id)) {
        parsed_menu_item.submenu_id = generateUUIDv4();
    } else if (!has_sub_menu) {
        parsed_menu_item.submenu_id = undefined;
    }

    return {
        menu_item: parseSavedMenuItem(parsed_menu_item),
        reference_type: (has_sub_menu)
            ? ReferenceTypeEnum.MENU
            : ReferenceTypeEnum.PAGE
    }
}

function parseSavedMenuItem(menu_item: MenuItemRestDto): MenuItemRestDto {
    const parsed_menu_item = JSON.parse(JSON.stringify(menu_item)) as MenuItemRestDto;

    if (stringHasContent(parsed_menu_item.submenu_id)) {
        parsed_menu_item.page_reference = undefined;
    } else if (hasInternalPageReference(parsed_menu_item.page_reference) && parsed_menu_item.page_reference) {
        parsed_menu_item.page_reference.external_url = undefined;
        parsed_menu_item.submenu_id = undefined;
    } else if (hasExternalPageReference(parsed_menu_item.page_reference) && parsed_menu_item.page_reference) {
        parsed_menu_item.page_reference.internal_page_reference = undefined;
        parsed_menu_item.submenu_id = undefined;
    } else {
        // todo translate
        const exceptions = [
            {code: '', message: 'Menu item ongeldig opgebouwd. Voeg een link of een submenu toe.'}
        ] as Exception[];
        throw new DpExceptions(exceptions);
    }

    return parsed_menu_item;
}

function parseSearchedMenuItem(fullMenuItem: FullMenuItemRestDto): FullMenuItemRestDto {
    if (!fullMenuItem.menu_item) {
        const exceptions = [
            {code: '', message: 'Menu item does not contain menu_item object.'}
        ] as Exception[];
        throw new DpExceptions(exceptions);
    }
    const parsed_menu_item = JSON.parse(JSON.stringify(fullMenuItem.menu_item)) as MenuItemRestDtoModel;

    if (stringHasContent(parsed_menu_item.submenu_id)) {
        parsed_menu_item.page_reference = new PageReferenceRestDtoModel(
            ContentPageReferenceRestDtoModel.createWithDefaults(),
            null,
            TargetEnum.SELF
        )
    } else if (hasInternalPageReference(parsed_menu_item.page_reference) && parsed_menu_item.page_reference) {
        parsed_menu_item.page_reference.external_url = null;
        parsed_menu_item.submenu_id = null;
    } else if (hasExternalPageReference(parsed_menu_item.page_reference) && parsed_menu_item.page_reference) {
        parsed_menu_item.page_reference.internal_page_reference = ContentPageReferenceRestDtoModel.createWithDefaults();
        parsed_menu_item.submenu_id = null;
    } else {
        const exceptions = [
            {code: '', message: 'Menu item built incorrectly.'}
        ] as Exception[];
        throw new DpExceptions(exceptions);
    }

    return {
        id: fullMenuItem.id,
        menu_item: parsed_menu_item
    };
}
