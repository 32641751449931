import {RgbRestDto} from "@/api/pharma-cms-content-block/models";

export class RgbRestDtoModel implements RgbRestDto {
    red: number;
    green: number;
    blue: number;

    constructor(red: number, green: number, blue: number) {
        this.red = red;
        this.green = green;
        this.blue = blue;
    }

    public static createWithDefaults(): RgbRestDtoModel {
        return new this(0, 0, 0);
    }

    public static cloneFrom(source: RgbRestDtoModel): RgbRestDtoModel {
        return new this(
            source.red,
            source.green,
            source.blue
        );
    }

}
