
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {ProductBrandOrderRestService} from "@/services/rest/cpc-mgmt/ProductBrandOrderRestService";
import {
  CodeDescriptionRestDto,
  ProductBrandOrderOverviewRestDto
} from "@/api/pharma-cpc-mgmt/models";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {useToast} from "vue-toastification";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {mapActions, mapGetters} from "vuex";
import {stringHasContent} from "@/helpers/functions/string";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import {BrandTypeEnum} from "@/api/enums/brand-type-enum";

export default defineComponent({
  name: "BrandOrder",
  components: {CatalogBuildStatus, BaseTitle},
  data() {
    return {
      exceptions: [] as Exception[],
      isReady: false as boolean,
      isReadySearch: true as boolean,

      addLaboModalId: 'addBrandOrderFromLaboModal' as string,
      addTrademarkModalId: 'addBrandOrderFromTrademarkModal' as string,
      toaster: useToast(),

      brandOrders: [] as ProductBrandOrderOverviewRestDto[],
      searchLaboQuery: '' as string,
      searchTrademarkQuery: '' as string,
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    BrandTypeEnum() {
      return BrandTypeEnum
    },
    searchedLabos(): CodeDescriptionRestDto[] {
      return this.getProductBrands;
    },
    searchedTrademarks(): CodeDescriptionRestDto[] {
      return this.getProductTrademarks;
    },
    ...mapGetters('cpc_product', ['getProductBrands', 'getProductTrademarks']),
    hasBrandOrders(): boolean {
      return arrayHasContent(this.brandOrders);
    },
    hasSearchedLabos(): boolean {
      return arrayHasContent(this.searchedLabos);
    },
    hasSearchedTrademarks(): boolean {
      return arrayHasContent(this.searchedTrademarks);
    },
    hasLaboSearchQuery(): boolean {
      return stringHasContent(this.searchLaboQuery);
    },
    hasTrademarkSearchQuery(): boolean {
      return stringHasContent(this.searchTrademarkQuery);
    },
    MoveActionEnum() {
      return MoveActionEnum
    },
  },
  methods: {
    ...mapActions('cpc_product', ['clearProductBrands', 'searchProductBrands', 'searchProductTrademarks']),
    async reloadContent(): Promise<void> {
      this.isReady = false;
      this.exceptions = [];

      try {
        this.clearLaboSearch();
        this.clearTrademarkSearch();
        this.brandOrders = await ProductBrandOrderRestService.getInstance().findBrandOrders();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },

    async addBrand(brandCode: string, type: BrandTypeEnum): Promise<void> {
      // console.log("addBrand: ", id);
      this.exceptions = [];

      try {
        await ProductBrandOrderRestService.getInstance().createBrandOrder(brandCode, type);
        this.clearLaboSearch();
        this.clearTrademarkSearch();

        this.toaster.success('Merk succesvol toegevoegd.');

        await this.reloadContent();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    deleteBrand(id: string): void {
      // console.log("deleteBrand: ", id);
      this.exceptions = [];

      try {
        const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
          title: 'Labo of merk verwijderen',
          body: 'Bent u zeker dat u dit labo of merk uit de lijst wilt verwijderen?',
          confirmButtonText: 'Verwijderen',
          confirmButtonClass: 'btn-danger',
        });

        onConfirm(async () => {
          await ProductBrandOrderRestService.getInstance().deleteBrandOrder(id);

          this.toaster.success('Merk succesvol verwijderd.');

          await this.reloadContent();
        });

        reveal();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async moveBrand(brand_code: string, moveAction: MoveActionEnum): Promise<void> {
      console.log("moveBrand: ", brand_code, moveAction);
      this.exceptions = [];

      try {
        await ProductBrandOrderRestService.getInstance().moveBrandOrder(brand_code, moveAction);

        this.toaster.success('Merk succesvol verplaatst.');

        await this.reloadContent();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },

    clearLaboSearch(): void {
      this.searchLaboQuery = '';
      this.clearProductBrands();
    },
    clearTrademarkSearch(): void {
      this.searchTrademarkQuery = '';
      this.clearProductBrands();
    },
    submitLaboSearch(): void {
      if (this.searchLaboQuery.length === 0) {
        this.clearLaboSearch();
      } else if (this.searchLaboQuery.length >= 2) {
        this.searchLabos();
      }
    },
    submitTrademarkSearch(): void {
      if (this.searchTrademarkQuery.length === 0) {
        this.clearTrademarkSearch();
      } else if (this.searchTrademarkQuery.length >= 2) {
        this.searchTrademarks();
      }
    },
    async searchLabos(): Promise<void> {
      this.isReadySearch = false;
      this.exceptions = [];

      try {
        await this.searchProductBrands({
          search_name: this.searchLaboQuery,
          language: this.$i18n.locale
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReadySearch = true;
    },
    async searchTrademarks(): Promise<void> {
      this.isReadySearch = false;
      this.exceptions = [];

      try {
        await this.searchProductTrademarks({
          search_name: this.searchTrademarkQuery,
          language: this.$i18n.locale
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReadySearch = true;
    },
  }
})
