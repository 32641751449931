
import {defineComponent, ref} from 'vue'
import {ColorRestDtoModel} from "@/models/api/pharma-cms-content-block/ColorRestDtoModel";
import InputColor from "@/components/UI/InputColor.vue";
import InputNumber from "@/components/UI/InputNumber.vue";
import {ColorPickerPayload} from "@/models/payload/ColorPickerPayload";

export default defineComponent({
  name: "ColorPicker",
  emits: ["changed"],
  components: {InputColor, InputNumber},
  props: {
    color: {
      type: [ColorRestDtoModel, Object],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    label_col_size: {
      type: Number,
      default: 2,
      required: false
    },
    label_bold: {
      type: Boolean,
      default: true,
      required: false
    },
    name: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const colorPickerPayload = ref(new ColorPickerPayload(props.color as ColorRestDtoModel));
    // console.log('color init', colorPickerPayload);

    return {
      colorPickerPayload
    };
  },
  watch: {
    colorPickerPayload: {
      handler(newPayload: ColorRestDtoModel) {
        // console.log('colorPickerPayload changed: ', newPayload);
        this.$emit('changed', newPayload);
      },
      deep: true,
    }
  },
  methods: {
    onColorChanged(hex: string) {
      this.colorPickerPayload.hex = hex;
      this.colorPickerPayload.setRGB(hex);
      // console.log('onColorChanged: ', hex, this.colorPickerPayload);
    },
  }
});
