/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-License API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ProductFeatureAuthorizationRestDto } from '../models';
/**
 * ProductFeatureEndpointsApi - axios parameter creator
 * @export
 */
export const ProductFeatureEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This api endpoint is called by a customer that is specified in the x-dp-customer-code header, therefore is secured with an access token of the pharmacy user pool.
         * @summary REST.API.LIC.01 Find the product feature authorizations for a customer.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {string} [filter_module_code] Filter the results based on the module to which the feature belongs.
         * @param {string} [filter_product_code] Filter the results based on the prodduct to which the feature belongs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductFeatureAuthorization: async (x_dp_customer_code?: string, filter_module_code?: string, filter_product_code?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/license_info/v1/license/product_feature/authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_module_code || typeof(filter_module_code) === "boolean") {
                localVarQueryParameter['filter_module_code'] = filter_module_code;
            }

            if (!!filter_product_code || typeof(filter_product_code) === "boolean") {
                localVarQueryParameter['filter_product_code'] = filter_product_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This api endpoint is called by an application, therefore it is secured with an access token  for parters.
         * @summary REST.API.LIC.02 Find the product feature authorizations for a customer.
         * @param {string} customer_code Code that uniquely identifies the Digital Pharma customer.
         * @param {string} [filter_module_code] Filter the results based on the module to which the feature belongs.
         * @param {string} [filter_product_code] Filter the results based on the prodduct to which the feature belongs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductFeatureAuthorizationForApplication: async (customer_code: string, filter_module_code?: string, filter_product_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling findProductFeatureAuthorizationForApplication.');
            }
            const localVarPath = `/app/license_info/v1/license/product_feature/authorization/{customer_code}`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_module_code || typeof(filter_module_code) === "boolean") {
                localVarQueryParameter['filter_module_code'] = filter_module_code;
            }

            if (!!filter_product_code || typeof(filter_product_code) === "boolean") {
                localVarQueryParameter['filter_product_code'] = filter_product_code;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductFeatureEndpointsApi - functional programming interface
 * @export
 */
export const ProductFeatureEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * This api endpoint is called by a customer that is specified in the x-dp-customer-code header, therefore is secured with an access token of the pharmacy user pool.
         * @summary REST.API.LIC.01 Find the product feature authorizations for a customer.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {string} [filter_module_code] Filter the results based on the module to which the feature belongs.
         * @param {string} [filter_product_code] Filter the results based on the prodduct to which the feature belongs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductFeatureAuthorization(_axios: AxiosInstance, x_dp_customer_code?: string, filter_module_code?: string, filter_product_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductFeatureAuthorizationRestDto>>> {
            const localVarAxiosArgs = await ProductFeatureEndpointsApiAxiosParamCreator(configuration).findProductFeatureAuthorization(x_dp_customer_code, filter_module_code, filter_product_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This api endpoint is called by an application, therefore it is secured with an access token  for parters.
         * @summary REST.API.LIC.02 Find the product feature authorizations for a customer.
         * @param {string} customer_code Code that uniquely identifies the Digital Pharma customer.
         * @param {string} [filter_module_code] Filter the results based on the module to which the feature belongs.
         * @param {string} [filter_product_code] Filter the results based on the prodduct to which the feature belongs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductFeatureAuthorizationForApplication(_axios: AxiosInstance, customer_code: string, filter_module_code?: string, filter_product_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductFeatureAuthorizationRestDto>>> {
            const localVarAxiosArgs = await ProductFeatureEndpointsApiAxiosParamCreator(configuration).findProductFeatureAuthorizationForApplication(customer_code, filter_module_code, filter_product_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductFeatureEndpointsApi - object-oriented interface
 * @export
 * @class ProductFeatureEndpointsApi
 * @extends {BaseAPI}
 */
export class ProductFeatureEndpointsApi extends BaseAPI {
    /**
     * This api endpoint is called by a customer that is specified in the x-dp-customer-code header, therefore is secured with an access token of the pharmacy user pool.
     * @summary REST.API.LIC.01 Find the product feature authorizations for a customer.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {string} [filter_module_code] Filter the results based on the module to which the feature belongs.
     * @param {string} [filter_product_code] Filter the results based on the prodduct to which the feature belongs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFeatureEndpointsApi
     */
     public findProductFeatureAuthorization(x_dp_customer_code?: string, filter_module_code?: string, filter_product_code?: string, options?: any) {
        return ProductFeatureEndpointsApiFp(this.configuration).findProductFeatureAuthorization(this.axios, x_dp_customer_code, filter_module_code, filter_product_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This api endpoint is called by an application, therefore it is secured with an access token  for parters.
     * @summary REST.API.LIC.02 Find the product feature authorizations for a customer.
     * @param {string} customer_code Code that uniquely identifies the Digital Pharma customer.
     * @param {string} [filter_module_code] Filter the results based on the module to which the feature belongs.
     * @param {string} [filter_product_code] Filter the results based on the prodduct to which the feature belongs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFeatureEndpointsApi
     */
     public findProductFeatureAuthorizationForApplication(customer_code: string, filter_module_code?: string, filter_product_code?: string, options?: any) {
        return ProductFeatureEndpointsApiFp(this.configuration).findProductFeatureAuthorizationForApplication(this.axios, customer_code, filter_module_code, filter_product_code, options).then((request) => request(this.axios, this.basePath));
    }

}
