import {
    BackgroundImageRestDto, BorderRadiusRestDto,
    cloneContentBlockInRowRestDtoMetaData, ColorRestDto,
    ContentBlockInRowRestDto, DeviceVisibilityRestDto,
} from "@/api/pharma-cms-content-page/models";
import {ContentBlockStructureEnum} from "@/models/enum/ContentBlockStructureEnum";
import {BackgroundColorEnum} from "@/api/enums/background-color-enum";
import {ColumnHorizontalAlignmentEnum} from "@/api/enums/column-horizontal-alignment-enum";
import {ColumnWidthEnum} from "@/api/enums/column-width-enum";
import {PaddingBottomEnum} from "@/api/enums/padding-bottom-enum";
import {PaddingLeftEnum} from "@/api/enums/padding-left-enum";
import {PaddingRightEnum} from "@/api/enums/padding-right-enum";
import {PaddingTopEnum} from "@/api/enums/padding-top-enum";
import {ContentBlockInRowRestDtoModel} from "@/models/ContentBlockInRowRestDtoModel";
import {RgbRestDtoModel} from "@/models/api/pharma-cms-content-page/RgbRestDtoModel";

export class BorderRadiusRestDtoModel implements BorderRadiusRestDto {
    top_left_in_px: number;
    top_right_in_px: number;
    bottom_left_in_px: number;
    bottom_right_in_px: number;
    constructor(top_left_in_px: number, top_right_in_px: number, bottom_left_in_px: number, bottom_right_in_px: number) {
        this.top_left_in_px = top_left_in_px;
        this.top_right_in_px = top_right_in_px;
        this.bottom_left_in_px = bottom_left_in_px;
        this.bottom_right_in_px = bottom_right_in_px;
    }

    static createWithDefaults(): BorderRadiusRestDtoModel {
        return new this(0,0,0,0);
    }

    public static cloneFrom(source: BorderRadiusRestDtoModel): BorderRadiusRestDtoModel {
        return new this(
            source.top_left_in_px,
            source.top_right_in_px,
            source.bottom_left_in_px,
            source.bottom_right_in_px,
        );
    }

}
