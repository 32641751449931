
import {defineComponent} from "vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-mgmt/models";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {PropType} from "vue/dist/vue";

export default defineComponent({
  name: "SelectBrandsSelectedItem",
  emits: ["removeBrand"],
  props: {
    selectedBrandCode: {
      type: String,
      required: true
    },
    catalogCustomerCode: {
      type: String,
      required: false,
    },
    pimLaboBrands: {
      type: Array as PropType<CodeDescriptionRestDto[]>,
      required: false
    }
  },
  computed: {
    ...mapState(useCustomerStore, ["getAllBrandsCustomer"]),
    brandName(): string | null {
      if (this.pimLaboBrands) {
        return this.pimLaboBrands
          .find((brand: CodeDescriptionRestDto) => brand.code === this.selectedBrandCode)?.description as string;
      }
      return this.getAllBrandsCustomer(this.catalogCustomerCode)
        .find((brand: any) => brand.code === this.selectedBrandCode)?.description as string;
    },
  },
  methods: {
    removeBrand(brand_code: string) {
      this.$emit('removeBrand', brand_code);
    },
  }
});
