export class PimProductsCriteriaModel {
    filter_cnk_code?: number | null;
    filter_belmedis_availability_code?: string | null;
    filter_febelco_availability_code?: string|null;
    filter_photography_status_code?: string | null;
    filter_iprins_usage_type?: string | null;
    filter_category_code_apb?: string | null;
    filter_category_code_apb_legal?: string | null;
    filter_category_code_internal?: string | null;
    filter_category_code_dp_managed?: string | null;
    filter_dp_managed_category_cardinality?: string | null;
    filter_is_not_in_internal_category?: boolean | null;
    expect_products_in_subcategory?: boolean | null;
    filter_brand_code?: string | null;
    filter_is_image_available?: boolean | null;
    filter_is_apb_out_of_business?: boolean | null;
    filter_is_active?: boolean | null;
    search_keyword?: string | null;
    expect_categories?: boolean | null;

    constructor() {
    }

    public static createWithDefaults(): PimProductsCriteriaModel {
        const model = new this();

        model.filter_cnk_code = null;
        model.filter_belmedis_availability_code = null;
        model.filter_febelco_availability_code = null;
        model.filter_photography_status_code = null;
        model.filter_iprins_usage_type = null;
        model.filter_category_code_apb = null;
        model.filter_category_code_apb_legal = null;
        model.filter_category_code_internal = null;
        model.filter_category_code_dp_managed = null;
        model.filter_dp_managed_category_cardinality = null;
        model.filter_is_not_in_internal_category = null;
        model.expect_products_in_subcategory = false;
        model.filter_brand_code = null;
        model.filter_is_image_available = null;
        model.filter_is_apb_out_of_business = false;
        model.filter_is_active = true;
        model.search_keyword = null;
        model.expect_categories = true;

        return model;
    }

    public static createWithCnkOnly(cnk_code?: number | null): PimProductsCriteriaModel {
        const model = this.createWithDefaults();

        model.filter_cnk_code = cnk_code;
        model.expect_products_in_subcategory = null;
        model.filter_is_apb_out_of_business = null;
        model.filter_is_active = null;

        return model;
    }
}
