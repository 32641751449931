import {AuthContext} from "@/context/AuthContext";

export function registerAuthInterceptor(instance) {
  instance.interceptors.request.use(
    (config) => {
      const token = AuthContext.oauth2Token.idToken;
      if (token) {
          config.headers['Authorization'] = token;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}
