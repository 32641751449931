import {CustomerService} from "@/services/business/CustomerService";
import {AuthContext} from "@/context/AuthContext";
import {AuthRestService} from "@/services/rest/AuthRestService";
import {Storage} from "@/models/facade/Storage";
import {LocalStorageKeyEnum} from "@/models/enum/LocalStorageKeyEnum";
import {CustomerContext} from "@/context/CustomerContext";
import {AuthInfoModel} from "@/models/AuthInfoModel";
import {CustomerStore} from "@/main";
import {Config} from "@/models/facade/Config";

let timer;

function fetchJwt(idToken) {
    const tokenParts = idToken.split('.');
    const encodedPayload = tokenParts[1];
    const rawPayload = atob(encodedPayload);
    return JSON.parse(rawPayload);
}

function buildUser(idToken) {
    const jwt = fetchJwt(idToken);

    return {first_name: jwt.name, last_name: jwt.family_name};
}

export default {
    state() {
        return {
            authInfo: AuthInfoModel.createWithDefaults()
        };
    },
    actions: {
        async login(context, payload) {
            return context.dispatch('auth', {
                ...payload,
                mode: 'login'
            });
        },
        async signup(context, payload) {
            return context.dispatch('auth', {
                ...payload,
                mode: 'signup'
            });
        },
        async auth(context, payload) {
            const mode = payload.mode;

            if (mode === 'signup') {
                throw new Error("Signup not supported.")
            }

            window.location.replace(
                AuthRestService.getInstance().getLoginUrl()
            );
        },
        async handleOAuth2V2Callback(context, payload) {
            let token;

            try {
                token = await AuthRestService.getInstance().getTokenByCode(payload.code, payload.state);
                // console.log('handleOAuth2V2Callback.token', token);
            } catch (error) {
                return context.dispatch('autoLogout');
            }

            AuthContext.setOAuth2Token(token);
            Storage.local.set(LocalStorageKeyEnum.OAUTH2_TOKEN, JSON.stringify(token));

            timer = setTimeout(function () {
                context.dispatch('refreshAuth');
            }, ((token.expiresIn - 100) * 1000));

            context.commit("setAuthInfo", new AuthInfoModel(
                null,
                buildUser(token.idToken),
                token.accessToken,
                token.idToken,
                false
            ));
        },
        async refreshAuth(context) {
            let token;

            try {
                token = await AuthRestService.getInstance().refreshToken(AuthContext.getOAuth2TokenOrFail().refreshToken);
            } catch (error) {
                return context.dispatch('autoLogout');
            }

            AuthContext.setOAuth2Token(token);
            Storage.local.set(LocalStorageKeyEnum.OAUTH2_TOKEN, JSON.stringify(token));

            clearTimeout(timer);
            timer = setTimeout(function () {
                context.dispatch('refreshAuth');
            }, ((token.expiresIn - 100) * 1000));

            context.commit("setAuthInfo", new AuthInfoModel(
                CustomerContext.getCustomerCode(),
                buildUser(token.idToken),
                token.accessToken,
                token.idToken,
                false
            ));
        },
        doAutoLogin(context) {
            const currentCustomerCode = Storage.local.get(LocalStorageKeyEnum.CURRENT_CUSTOMER_CODE);
            const pharmacyCode = Storage.local.get(LocalStorageKeyEnum.CURRENT_PHARMACY_CODE);

            let oauth2Token = Storage.local.get(LocalStorageKeyEnum.OAUTH2_TOKEN, null);

            if (oauth2Token === null) {
                return;
            }

            oauth2Token = JSON.parse(oauth2Token);

            if (!oauth2Token) {
                context.dispatch("autoLogout");
                return;
            }

            const expiresIn = +oauth2Token.expiresAt - new Date().getTime() - (100 * 1000);

            if (expiresIn <= 0) {
                context.dispatch("autoLogout");
                return;
            }

            AuthContext.setOAuth2Token(oauth2Token);

            timer = setTimeout(function () {
                context.dispatch("refreshAuth");
            }, expiresIn);

            if (oauth2Token.accessToken && oauth2Token.idToken) {
                context.commit("setAuthInfo", new AuthInfoModel(
                    currentCustomerCode,
                    buildUser(oauth2Token.idToken),
                    oauth2Token.accessToken,
                    oauth2Token.idToken,
                    false
                ));
                CustomerStore.setPharmacyCode(pharmacyCode);
            }
        },
        changeCustomer(context, customer_code) {
            if (!customer_code) {
                customer_code = context.getters.getCurrentCustomerCode;
            }

            if (!customer_code) {
                const jwt = fetchJwt(AuthContext.getOAuth2TokenOrFail().idToken)
                let currentCustomer = null;
                if (jwt.profile) {
                    currentCustomer = jwt.profile;
                    customer_code = currentCustomer.split('|').shift();
                } else if (jwt['custom:cug_code'] === 'DP') {
                    customer_code = Config.getInstance().getConfig().ADMIN_CUSTOMER_CODE;
                }
            }

            context.commit("setCurrentCustomerCode", customer_code);
            CustomerService.getInstance().changeCustomerHook(customer_code);
        },
        logout(context) {
            AuthContext.clear();
            Storage.local.remove(LocalStorageKeyEnum.OAUTH2_TOKEN);

            Storage.local.remove(LocalStorageKeyEnum.CURRENT_CUSTOMER_CODE);
            Storage.local.remove(LocalStorageKeyEnum.CURRENT_PHARMACY_CODE);

            Storage.local.remove(LocalStorageKeyEnum.ACCESS_TOKEN);
            Storage.local.remove(LocalStorageKeyEnum.ID_TOKEN);
            Storage.local.remove(LocalStorageKeyEnum.TOKEN_EXPIRATION);

            clearTimeout(timer);

            context.commit('setAuthInfo', {
                idToken: null,
                accessToken: null,
                user: null,
                currentCustomerCode: null,
            });
        },
        autoLogout(context) {
            context.dispatch('logout');
            context.commit('setAutoLogout');
        }
    },
    mutations: {
        setCurrentCustomerCode(state, customer_code) {
            state.authInfo.currentCustomerCode = customer_code;

            Storage.local.set(LocalStorageKeyEnum.CURRENT_CUSTOMER_CODE, customer_code);
        },
        setAuthInfo(state, authInfo) {
            state.authInfo = authInfo;
        },
        setAutoLogout(state) {
            state.authInfo.didAutoLogout = true;
        }
    },
    getters: {
        getUser(state) {
            return state.authInfo.user;
        },
        getCurrentCustomerCode(state) {
            return state.authInfo.currentCustomerCode;
        },
        getIdToken(state) {
            return state.authInfo.idToken;
        },
        getAccessToken(state) {
            return state.authInfo.accessToken;
        },
        isAuthenticated(state) {
            return !!state.authInfo.idToken;
        },
        didAutoLogout(state) {
            return state.authInfo.didAutoLogout;
        }
    }
};
