import {Pp2ProductsCriteriaRestDto} from "@/api/pharma-pim-pp2/models";
import {PimProductsCriteriaModel} from "@/models/criteria/PimProductsCriteriaModel";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";

export class Pp2ProductsCriteriaRestDtoModel implements Pp2ProductsCriteriaRestDto {
    accept?: string | null;

    x_dp_customer_code?: string | null;
    x_dp_language?: string | null;

    page?: number | null;
    page_size?: number | null;

    filter_cnk_code?: number | null;
    filter_belmedis_availability_code?: string | null;
    filter_febelco_availability_code?: string|null;
    filter_photography_status_code?: string | null;
    filter_iprins_usage_type?: string | null;
    filter_category_code_apb?: string | null;
    filter_category_code_apb_legal?: string | null;
    filter_category_code_internal?: string | null;
    filter_category_code_dp_managed?: string | null;
    filter_dp_managed_category_cardinality?: string | null;
    filter_is_not_in_internal_category?: boolean | null;
    expect_products_in_subcategory?: boolean | null;
    filter_brand_code?: string | null;
    filter_is_image_available?: boolean | null;
    filter_is_apb_out_of_business?: boolean | null;
    filter_is_active?: boolean | null;
    search_keyword?: string | null;
    expect_categories?: boolean | null;

    constructor(criteria: PimProductsCriteriaModel, paging?: PimProductsPagingModel, accept?: string | null) {
        this.filter_cnk_code = criteria.filter_cnk_code;
        this.filter_belmedis_availability_code = criteria.filter_belmedis_availability_code;
        this.filter_febelco_availability_code = criteria.filter_febelco_availability_code;
        this.filter_photography_status_code = criteria.filter_photography_status_code;
        this.filter_iprins_usage_type = criteria.filter_iprins_usage_type;
        this.filter_category_code_apb = criteria.filter_category_code_apb;
        this.filter_category_code_apb_legal = criteria.filter_category_code_apb_legal;
        this.filter_category_code_internal = criteria.filter_category_code_internal;
        this.filter_category_code_dp_managed = criteria.filter_category_code_dp_managed;
        this.filter_dp_managed_category_cardinality = criteria.filter_dp_managed_category_cardinality;
        this.filter_is_not_in_internal_category = criteria.filter_is_not_in_internal_category;
        this.expect_products_in_subcategory = criteria.expect_products_in_subcategory;
        this.filter_brand_code = criteria.filter_brand_code;
        this.filter_is_image_available = criteria.filter_is_image_available;
        this.filter_is_apb_out_of_business = criteria.filter_is_apb_out_of_business;
        this.filter_is_active = criteria.filter_is_active;
        this.search_keyword = criteria.search_keyword;
        this.expect_categories = criteria.expect_categories;

        this.page = paging?.number;
        this.page_size = paging?.size ? parseInt(paging.size) : undefined;

        this.accept = accept;
    }

    public static createWithDefaults(): Pp2ProductsCriteriaRestDtoModel {
        return new this(
            PimProductsCriteriaModel.createWithDefaults(),
            PimProductsPagingModel.createWithDefaults()
        );
    }
}
