<template>
  <Teleport to="body">
    <div
        :id="modalId"
        class="modal fade"
        aria-labelledby="modalLabel"
        aria-hidden="true"
        tabindex="-1"
        data-bs-focus="false"
        v-bind="staticAttribute"
    >
      <div :class="['modal-dialog', dialogClasses]">
        <div class="modal-content">
          <div v-if="!noHeader" class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <base-modal-button mode="close" class="btn-close" aria-label="Close"></base-modal-button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div v-if="!noFooter" class="modal-footer">
            <slot name="footer">
              <base-modal-button mode="close" class="btn btn-outline-primary">{{ $t('cancel') }}</base-modal-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    modalId: {
      type: String,
      required: false,
      default: 'baseModal',
    },
    dialogClasses: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false,
    },
    noHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    noFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    staticAttribute() {
      if (!this.isStatic) {
        return null;
      }

      return {'data-bs-backdrop': 'static'}
    },
  }
};
</script>
