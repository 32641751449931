import {SymbolRestDto} from "@/api/pharma-cms-content-block/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {SymbolFamilyEnum} from "@/api/enums/symbol-family-enum";
import {SymbolTargetEnum} from "@/api/enums/symbol-target-enum";
import {SymbolWeightEnum} from "@/api/enums/symbol-weight-enum";
import {SymbolGradeEnum} from "@/api/enums/symbol-grade-enum";
import {SymbolOpticalSizeEnum} from "@/api/enums/symbol-optical-size-enum";
import {SymbolHorizontalPositionEnum} from "@/api/enums/symbol-horizontal-position-enum";
import {SymbolVerticalPositionEnum} from "@/api/enums/symbol-vertical-position-enum";
import {ColorRestDtoModel} from "@/models/api/pharma-cms-content-block/ColorRestDtoModel";

export class SymbolRestDtoModel implements SymbolRestDto {
    family_code: SymbolFamilyEnum;
    name_code: string;
    symbol_target?: SymbolTargetEnum | null;
    symbol_url?: MultilingualRestDtoModel | null;
    symbol_page_id?: MultilingualRestDtoModel | null;
    size_in_rem: number;
    default_state_color: ColorRestDtoModel;
    hover_state_color: ColorRestDtoModel;
    is_filled?: boolean | null;
    weight_code: SymbolWeightEnum;
    grade_code: SymbolGradeEnum;
    optical_size_code: SymbolOpticalSizeEnum;
    horizontal_position_code: SymbolHorizontalPositionEnum;
    vertical_position_code: SymbolVerticalPositionEnum;

    constructor(family_code: SymbolFamilyEnum, name_code: string, size_in_rem: number, default_state_color: ColorRestDtoModel, hover_state_color: ColorRestDtoModel, weight_code: SymbolWeightEnum, grade_code: SymbolGradeEnum, optical_size_code: SymbolOpticalSizeEnum, horizontal_position_code: SymbolHorizontalPositionEnum, vertical_position_code: SymbolVerticalPositionEnum) {
        this.family_code = family_code;
        this.name_code = name_code;
        this.size_in_rem = size_in_rem;
        this.default_state_color = default_state_color;
        this.hover_state_color = hover_state_color;
        this.weight_code = weight_code;
        this.grade_code = grade_code;
        this.optical_size_code = optical_size_code;
        this.horizontal_position_code = horizontal_position_code;
        this.vertical_position_code = vertical_position_code;
    }

    public static createWithDefaults(): SymbolRestDtoModel {
        const model = new this(
            SymbolFamilyEnum.MATERIAL_SYMBOLS_OUTLINED,
            'settings',
            2,
            ColorRestDtoModel.createWithDefaults(),
            ColorRestDtoModel.createWithDefaults(),
            SymbolWeightEnum.FW_400,
            SymbolGradeEnum.GRADE_200,
            SymbolOpticalSizeEnum.OPTSZ_48,
            SymbolHorizontalPositionEnum.LEFT,
            SymbolVerticalPositionEnum.CENTER
        );
        model.is_filled = false;

        return model;
    }
}
