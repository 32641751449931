
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {mapActions, mapGetters} from "vuex";
import {ProductFilterOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";
import {arrayHasContent} from "@/helpers/functions/arrays";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {AbilityContext} from "@/context/AbilityContext";

export default defineComponent({
  name: "ProductFiltersDetail",
  components: {BaseTitle, CatalogBuildStatus},
  data() {
    return {
      exceptions: [] as Exception[],
      isReady: false as boolean,
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters("cpc_mgmt", ["getProductFilterOverview"]),
    AbilityContext() {
      return AbilityContext
    },
    productFilterOverview(): ProductFilterOverviewRestDto[] {
      return this.getProductFilterOverview;
    },
    hasProductFilterOverview(): boolean {
      return arrayHasContent(this.productFilterOverview);
    }
  },
  methods: {
    ...mapActions("cpc_mgmt", ["deleteComplexProductFilter", "searchProductFilterOverview"]),
    async deleteProductFilter(filterCode: string) {
      try {
        const answer = window.confirm(this.$t('deleteConfirmation'));

        if (answer) {
          this.isReady = false;
          this.exceptions = [];

          await this.deleteComplexProductFilter(filterCode);
          await this.reloadContent();
        } else {
          return;
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.searchProductFilterOverview({is_named: true});
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    }
  }
});
