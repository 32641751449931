import catalogSettingsModule from "@/store/pharma-cpc-mgmt/catalog_settings";
import discountSchemeModule from "@/store/pharma-cpc-mgmt/discount_scheme";
import priceListModule from "@/store/pharma-cpc-mgmt/price_list";
import productFiltersModule from "@/store/pharma-cpc-mgmt/product_filters";
import starterSettingsModule from "@/store/pharma-cpc-mgmt/starter_settings";
import {DpException} from "@/exception/DpException";
import {
    addProductFilter,
    removeProductBrandFilter,
    removeProductTrademarkFilter,
    removeProductCategoryFilter,
    removeProductFilter
} from "@/store/pharma-cpc-mgmt/middleware";
import {
    ProductFilterCriteriumBrandRestDto,
    ProductFilterCriteriumTrademarkRestDto,
    ProductFilterCriteriumCategoryRestDto
} from "@/api/pharma-cpc-mgmt/models";
import {ProductFilterApplyTypeEnum} from "@/models/enum/ProductFilterApplyTypeEnum";

export default {
    namespaced: true,
    modules: {
        catalog_settings: catalogSettingsModule,
        discount_scheme: discountSchemeModule,
        price_list: priceListModule,
        product_filters: productFiltersModule,
        starter_settings: starterSettingsModule,
    },
    state() {
        return {
            search_brand_name: undefined as string | undefined,
            search_trademark_name: undefined as string | undefined,
            search_product: {
                brand_name: undefined as string | undefined,
                trademark_name: undefined as string | undefined,
                cnk_code: undefined as string | undefined,
                product_name: undefined as string | undefined,
                is_medicine: false as boolean,
                is_medical_aid: false as boolean,
                is_prescription_required: false as boolean,
                usage_type_code: undefined as string | undefined,
            } as object,
        }
    },
    actions: {
        addFilterCondition(context: any, payload: any) {
            context.commit('addFilterCondition', payload);
        },
        addProductBrandFilter(context: any, payload: any) {
            context.commit('addProductBrandFilter', payload);
        },
        addProductTrademarkFilter(context: any, payload: any) {
            context.commit('addProductTrademarkFilter', payload);
        },
        addProductCategoryFilter(context: any, payload: any) {
            context.commit('addProductCategoryFilter', payload);
        },
        removeFilterCondition(context: any, payload: any) {
            context.commit('removeFilterCondition', payload);
        },
        removeProductBrandFilter(context: any, payload: any) {
            context.commit('removeProductBrandFilter', payload);
        },
        removeProductTrademarkFilter(context: any, payload: any) {
            context.commit('removeProductTrademarkFilter', payload);
        },
        removeProductCategoryFilter(context: any, payload: any) {
            context.commit('removeProductCategoryFilter', payload);
        },
        clearSearchBrandName(context: any) {
            context.commit('setSearchBrandName', undefined);
        },
        clearSearchTrademarkName(context: any) {
            context.commit('setSearchTrademarkName', undefined);
        },
        clearSearchProduct(context: any, property?: string) {
            if (property) {
                context.commit('setSearchProductProperty', {
                    property: property,
                    value: property.startsWith('is') ? false : undefined,
                });
            } else {
                context.commit('setSearchProduct', {
                    brand_name: undefined,
                    trademark_name: undefined,
                    cnk_code: undefined,
                    product_name: undefined,
                    is_medicine: false,
                    is_medical_aid: false,
                    is_prescription_required: false,
                    usage_type_code: undefined,
                });
            }
        },
        setSearchBrandName(context: any, search_text: string) {
            context.commit('setSearchBrandName', search_text);
        },
        setSearchTrademarkName(context: any, search_text: string) {
            context.commit('setSearchTrademarkName', search_text);
        },
        setSearchProduct(context: any, payload: any) {
            context.commit('setSearchProduct', payload);
        },
        setSearchProductProperty(context: any, payload: any) {
            context.commit('setSearchProductProperty', payload);
        },
    },
    mutations: {
        addFilterCondition(state: any, payload: any) {
            const filter_condition_type = payload.filter_condition_type as string | undefined;
            const apply_on_type = payload.apply_on_type as ProductFilterApplyTypeEnum;

            switch (apply_on_type) {
                case ProductFilterApplyTypeEnum.DISCOUNT:
                    state.discount_scheme.discount_scheme.product_filter = addProductFilter(state.discount_scheme.discount_scheme.product_filter, filter_condition_type);
                    break;
                case ProductFilterApplyTypeEnum.CB:
                    state.product_filters.product_filter_complex = addProductFilter(state.product_filters.product_filter_complex, filter_condition_type);
                    break;
                default:
                    throw [new DpException('Unrecognized apply_on_type for addFilterCondition')];
            }
        },
        addProductBrandFilter(state: any, payload: any) {
            const filter_index = payload.filter_index as number;
            const product_brand_filter = payload.product_brand_filter as ProductFilterCriteriumBrandRestDto;
            const apply_on_type = payload.apply_on_type as ProductFilterApplyTypeEnum;

            switch (apply_on_type) {
                case ProductFilterApplyTypeEnum.DISCOUNT:
                    state.discount_scheme.discount_scheme.product_filter.filter_conditions[filter_index].product_brand_filters.push(product_brand_filter);
                    break;
                case ProductFilterApplyTypeEnum.CB:
                    state.product_filters.product_filter_complex.filter_conditions[filter_index].product_brand_filters.push(product_brand_filter);
                    break;
                default:
                    throw [new DpException('Unrecognized apply_on_type for addProductBrandFilter')];
            }

            // console.log('added')
            // console.log(state.discount_scheme.discount_scheme.filter_conditions[filter_index].product_brand_filters);
        },
        addProductTrademarkFilter(state: any, payload: any) {
            const filter_index = payload.filter_index as number;
            const product_trademark_filter = payload.product_trademark_filter as ProductFilterCriteriumTrademarkRestDto;
            const apply_on_type = payload.apply_on_type as ProductFilterApplyTypeEnum;

            switch (apply_on_type) {
                case ProductFilterApplyTypeEnum.DISCOUNT:
                    state.discount_scheme.discount_scheme.product_filter.filter_conditions[filter_index].product_trademark_filters.push(product_trademark_filter);
                    break;
                case ProductFilterApplyTypeEnum.CB:
                    state.product_filters.product_filter_complex.filter_conditions[filter_index].product_trademark_filters.push(product_trademark_filter);
                    break;
                default:
                    throw [new DpException('Unrecognized apply_on_type for addProductTrademarkFilter')];
            }

            // console.log('added')
            // console.log(state.discount_scheme.discount_scheme.filter_conditions[filter_index].product_trademark_filters);
        },
        addProductCategoryFilter(state: any, payload: any) {
            const filter_index = payload.filter_index as number;
            const product_category_filter = payload.product_category_filter as ProductFilterCriteriumCategoryRestDto;
            const apply_on_type = payload.apply_on_type as ProductFilterApplyTypeEnum;

            switch (apply_on_type) {
                case ProductFilterApplyTypeEnum.DISCOUNT:
                    state.discount_scheme.discount_scheme.product_filter.filter_conditions[filter_index].product_category_filters.push(product_category_filter);
                    break;
                case ProductFilterApplyTypeEnum.CB:
                    state.product_filters.product_filter_complex.filter_conditions[filter_index].product_category_filters.push(product_category_filter);
                    break;
                default:
                    throw [new DpException('Unrecognized apply_on_type for addProductCategoryFilter')];
            }

            // console.log('added')
            // console.log(state.discount_scheme.discount_scheme.filter_conditions[filter_index].product_category_filters);
        },
        removeFilterCondition(state: any, payload: any) {
            const filter_condition_index = payload.filter_condition_index as number;
            const apply_on_type = payload.apply_on_type as ProductFilterApplyTypeEnum;

            switch (apply_on_type) {
                case ProductFilterApplyTypeEnum.DISCOUNT:
                    state.discount_scheme.discount_scheme.product_filter = removeProductFilter(state.discount_scheme.discount_scheme.product_filter, filter_condition_index);
                    break;
                case ProductFilterApplyTypeEnum.CB:
                    state.product_filters.product_filter_complex = removeProductFilter(state.product_filters.product_filter_complex, filter_condition_index);
                    break;
                default:
                    throw [new DpException('Unrecognized apply_on_type for removeFilterCondition')];
            }
        },
        removeProductBrandFilter(state: any, payload: any) {
            const filter_index = payload.filter_index as number;
            const product_brand_filter = payload.product_brand_filter as ProductFilterCriteriumBrandRestDto;
            const apply_on_type = payload.apply_on_type as ProductFilterApplyTypeEnum;

            switch (apply_on_type) {
                case ProductFilterApplyTypeEnum.DISCOUNT:
                    state.discount_scheme.discount_scheme.product_filter = removeProductBrandFilter(state.discount_scheme.discount_scheme.product_filter, filter_index, product_brand_filter);
                    break;
                case ProductFilterApplyTypeEnum.CB:
                    state.product_filters.product_filter_complex = removeProductBrandFilter(state.product_filters.product_filter_complex, filter_index, product_brand_filter);
                    break;
                default:
                    throw [new DpException('Unrecognized apply_on_type for removeProductBrandFilter')];
            }

            // console.log('removed')
            // console.log(state.discount_scheme.discount_scheme.filter_conditions[filter_index].product_brand_filters);
        },
        removeProductTrademarkFilter(state: any, payload: any) {
            const filter_index = payload.filter_index as number;
            const product_trademark_filter = payload.product_trademark_filter as ProductFilterCriteriumTrademarkRestDto;
            const apply_on_type = payload.apply_on_type as ProductFilterApplyTypeEnum;

            switch (apply_on_type) {
                case ProductFilterApplyTypeEnum.DISCOUNT:
                    state.discount_scheme.discount_scheme.product_filter = removeProductTrademarkFilter(state.discount_scheme.discount_scheme.product_filter, filter_index, product_trademark_filter);
                    break;
                case ProductFilterApplyTypeEnum.CB:
                    state.product_filters.product_filter_complex = removeProductTrademarkFilter(state.product_filters.product_filter_complex, filter_index, product_trademark_filter);
                    break;
                default:
                    throw [new DpException('Unrecognized apply_on_type for removeProductTrademarkFilter')];
            }

            // console.log('removed')
            // console.log(state.discount_scheme.discount_scheme.filter_conditions[filter_index].product_trademark_filters);
        },
        removeProductCategoryFilter(state: any, payload: any) {
            const filter_index = payload.filter_index as number;
            const product_category_filter = payload.product_category_filter as ProductFilterCriteriumCategoryRestDto;
            const apply_on_type = payload.apply_on_type as ProductFilterApplyTypeEnum;

            switch (apply_on_type) {
                case ProductFilterApplyTypeEnum.DISCOUNT:
                    state.discount_scheme.discount_scheme.product_filter = removeProductCategoryFilter(state.discount_scheme.discount_scheme.product_filter, filter_index, product_category_filter);
                    break;
                case ProductFilterApplyTypeEnum.CB:
                    state.product_filters.product_filter_complex = removeProductCategoryFilter(state.product_filters.product_filter_complex, filter_index, product_category_filter);
                    break;
                default:
                    throw [new DpException('Unrecognized apply_on_type for removeProductCategoryFilter')];
            }

            // console.log('removed')
            // console.log(state.discount_scheme.filter_conditions[filter_index].product_category_filters);
        },
        setSearchBrandName(state: any, search_text: string) {
            state.search_brand_name = search_text;
        },
        setSearchTrademarkName(state: any, search_text: string) {
            state.search_trademark_name = search_text;
        },
        setSearchProduct(state: any, payload: any) {
            for (const search_product_prop in state.search_product) {
                if (search_product_prop in payload) {
                    state.search_product[search_product_prop] = payload[search_product_prop];
                }
            }
        },
        setSearchProductProperty(state: any, payload: any) {
            state.search_product[payload.property] = payload.value;
        },
    },
    getters: {
        getSearchBrandName(state: any): string {
            return state.search_brand_name;
        },
        getSearchTrademarkName(state: any): string {
            return state.search_trademark_name;
        },
        getSearchProduct(state: any): object {
            return state.search_product;
        },
    },
}
