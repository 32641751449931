import {RestService} from "@/services/rest/RestService";
import {DiscountSchemeAPIApi} from "@/api/pharma-cpc-mgmt";
import {
    DiscountSchemeRestDto,
    DiscountStepRestDto,
    DiscountStepResultsOverviewRestDto
} from "@/api/pharma-cpc-mgmt/models";
import {DiscountTypeEnum} from "@/api/enums/discount-type-enum";

export class DiscountSchemeRestService extends RestService {

    private apiService: DiscountSchemeAPIApi;

    protected constructor() {
        super();

        this.apiService = new DiscountSchemeAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
    }

    public async createDiscountScheme(body: DiscountSchemeRestDto): Promise<void> {
        this.normalizeFieldsForSave(body);
        await this.apiService.createDiscountScheme(body);
    }

    public async updateDiscountScheme(id: string, body: DiscountSchemeRestDto): Promise<void> {
        this.normalizeFieldsForSave(body);
        await this.apiService.updateDiscountScheme(body, id);
    }

    private normalizeFieldsForSave(body: DiscountSchemeRestDto) {
        if (body.type_code === DiscountTypeEnum.BUY_X_GET_Y_FREE) {
            body.discount_value = null;
        }
        if (body.type_code !== DiscountTypeEnum.BUY_X_GET_Y_FREE
            && body.type_code !== DiscountTypeEnum.BUY_X_GET_Y_PERCENTAGE
            && body.type_code !== DiscountTypeEnum.BUY_X_GET_Y_AMOUNT
        ) {
            body.buy_quantity = null;
            body.get_quantity = null;
            body.combination_scope_code = null;
            body.combination_scope_code = null;
        }
    }

    public async findDiscountScheme(id: string): Promise<DiscountSchemeRestDto> {
        const response = await this.apiService.getDiscountScheme(id);

        return response.data;
    }

    public async findDiscountSteps(discountSchemeId: string): Promise<DiscountStepResultsOverviewRestDto> {
        const response = await this.apiService.findDiscountSteps(discountSchemeId);

        return response.data;
    }

    public async createDiscountStep(discountSchemeId: string, body: DiscountStepRestDto): Promise<void> {
        await this.apiService.createDiscountStep(body, discountSchemeId);
    }

    public async updateDiscountStep(discountSchemeId: string, stepId: string, body: DiscountStepRestDto): Promise<void> {
        await this.apiService.updateDiscountStep(body, discountSchemeId, stepId);
    }

    public async deleteDiscountStep(discountSchemeId: string, stepId: string): Promise<void> {
        await this.apiService.deleteDiscountStep(discountSchemeId, stepId);
    }
}
