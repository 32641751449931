import {DiscountStepRestDto, ProductPriceRestDto} from "@/api/pharma-cpc-mgmt/models";

export class DiscountStepRestDtoModel implements DiscountStepRestDto {
    quantity_from: number;
    discount_value: number;


    constructor(quantity_from: number, discount_value: number) {
        this.quantity_from = quantity_from;
        this.discount_value = discount_value;
    }

    public static createWithDefaults(quantity_from: number, discount_value: number): DiscountStepRestDtoModel {
        return new this(quantity_from, discount_value);
    }
}
