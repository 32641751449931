/*
* Overview of available enums: https://gitlab.com/digital-pharma-dp3/pharma-customer-product-catalog/-/tree/master/application/src/Model/Enum
*/
export enum EnumClassNameCpcEnum {
    APB_LEGAL_CATEGORY = "ApbLegalCategoryEnum",
    APB_PRODUCT_CATEGORY = "ApbProductCategoryEnum",
    BELMEDIS_AVAILABILITY = "BelmedisAvailabilityEnum",
    CATEGORY_CARDINALITY = "CategoryCardinalityEnum",
    FEBELCO_AVAILABILITY = "FebelcoAvailabilityEnum",
    IPRINS_USAGE_TYPE = "IPrinsUsageTypeEnum",
    PHOTOGRAPHY_STATUS_FILTER = "PhotographyStatusFilterEnum",
    PRODUCT_USAGE_TYPE = "ProductUsageTypeEnum",
    PRODUCT_PROPERTY_STRATEGY = "ProductPropertyStrategyEnum",
    DISCOUNT_TYPE = "DiscountTypeEnum",
    COMBINATION_DISOOUNT_SCOPE = "CombinationDiscountScopeEnum",
}
