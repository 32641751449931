
import {defineComponent} from 'vue'
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {AbilityContext} from "@/context/AbilityContext";
import {PimProductsOverviewPageLayoutEnum} from "@/models/enum/pim/PimProductsOverviewPageLayoutEnum";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import OverviewTable from "@/components/layouts/catalog/products/CatalogProductsOverviewTable.vue";
import OverviewControls from "@/components/layouts/pim/PimProductsOverviewControls.vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import OverviewLegend from "@/components/layouts/pim/PimProductsOverviewLegend.vue";
import {CatalogProductOverviewRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductOverviewRestDtoModel";
import {CatalogProductsRestService} from "@/services/rest/cpc-mgmt/CatalogProductsRestService";
import OverviewCriteria from "@/components/layouts/catalog/products/CatalogProductsOverviewCriteria.vue";
import {CatalogProductsCriteriaModel} from "@/models/criteria/CatalogProductsCriteriaModel";
import {
  PagedCatalogProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/PagedCatalogProductOverviewResultsRestDtoModel";

export default defineComponent({
  name: "CatalogProductsOverview",
  components: {
    OverviewLegend,
    BaseContainer,
    OverviewControls,
    OverviewTable,
    AlertError2,
    BaseTitle,
    OverviewCriteria
  },
  data() {
    return {
      criteriaInitialized: false as boolean,

      productsOverview: PagedCatalogProductOverviewResultsRestDtoModel.createWithDefaults(),
      productsOverviewUI: UIStateDto.createWithDefaults(),

      selectedDetailProduct: null as CatalogProductOverviewRestDtoModel | null,
    }
  },
  mounted() {
    if (this.getPimState.catalog_products_is_initialized) {
      this.reloadContent();
    } else {
      this.productsOverviewUI.setReady();
    }
  },
  computed: {
    ...mapState(useCustomerStore, ["getCriteria", "getPaging", "getPimState"]),
    AbilityContext() {
      return AbilityContext
    },
    PimProductsOverviewPageLayoutEnum() {
      return PimProductsOverviewPageLayoutEnum;
    },
    productsCriteria(): CatalogProductsCriteriaModel {
      return this.getCriteria.catalog_products;
    },
    productsPaging(): PimProductsPagingModel {
      return this.getPaging.pim_products;
    },
    hasProducts(): boolean {
      return arrayHasContent(this.productsOverview.results);
    }
  },
  methods: {
    onControlsChanged(): void {
      if (this.getPimState.catalog_products_is_initialized) {
        this.reloadContent();
      }
    },
    onCriteriaInitialized(): void {
      this.criteriaInitialized = true;
    },
    onCategorizationSubmitted(): void {
    },
    async reloadContent(): Promise<void> {
      this.productsOverviewUI
        .setNotReady()
        .clearError();

      try {

        this.productsOverview = await CatalogProductsRestService.getInstance()
          .findCatalogProducts(this.productsCriteria, this.productsPaging);

        this.getPimState.catalog_products_is_initialized = true;
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.productsOverviewUI.setReady();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productsOverviewUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.productsOverviewUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
})
