import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fa3f95b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "icons" }
const _hoisted_3 = { class: "cnk" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "price" }
const _hoisted_7 = {
  key: 0,
  class: "col-auto d-flex align-items-center flex-row"
}
const _hoisted_8 = { class: "current-price fw-600 pe-2" }
const _hoisted_9 = { class: "old-price text-decoration-line-through" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "discount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemIcons = _resolveComponent("ItemIcons")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: this.product.main_image_url,
          alt: this.product.product_info.name,
          style: {"width":"50px","height":"50px"}
        }, null, 8, _hoisted_1)
      ])
    ]),
    _createElementVNode("td", _hoisted_2, [
      _createVNode(_component_ItemIcons, {
        product: this.product
      }, null, 8, ["product"])
    ]),
    _createElementVNode("td", _hoisted_3, _toDisplayString(this.product.product_info.cnk_code), 1),
    _createElementVNode("td", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "btn btn-link text-start text-decoration-none text-black lh-1 p-0",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (this.openProductDetail && this.openProductDetail(...args)), ["prevent"]))
        }, _toDisplayString(this.product.product_info.name), 1)
      ])
    ]),
    _createElementVNode("td", _hoisted_6, [
      (this.product.discount && this.product.discount.label)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, " € " + _toDisplayString(this.product.product_price.sale_price_with_vat), 1),
            _createElementVNode("div", _hoisted_9, " € " + _toDisplayString(this.product.product_price.standard_price_with_vat), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_10, " € " + _toDisplayString(this.product.product_price.sale_price_with_vat), 1))
    ]),
    _createElementVNode("td", _hoisted_11, [
      _createElementVNode("div", null, _toDisplayString(this.product.discount?.label?.content ?? ''), 1)
    ])
  ]))
}