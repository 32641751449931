import {RestService} from "@/services/rest/RestService";
import {CodeDescriptionRestDto} from "@/api/pharma-order-settings/models";
import {EnumEndpointsApi} from "@/api/pharma-order-enum";
import {EnumContentRestDtoModel} from "@/models/api/pharma-order-enum/EnumContentRestDtoModel";
import {MultilingualFieldRestDto} from "@/api/pharma-order-enum/models";
import {UpdateEnumContentRestDtoModel} from "@/models/api/pharma-order-enum/UpdateEnumContentRestDtoModel";

export class EnumRestService extends RestService {

    private orderEnumApi: EnumEndpointsApi;

    protected constructor() {
        super();

        this.orderEnumApi = new EnumEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().orderUrl);
    }

    public async getOrderEnumDescription(enumClassName: string, enumCode: string): Promise<string> {
        const values = await this.getOrderEnumValuesByClassname(enumClassName);

        for (const val of values) {
            if (val.code === enumCode) {
                return (val.description === undefined || val.description === null ? '' : val.description);
            }
        }

        return '';
    }

    public async getOrderEnumValuesByClassname(enumClassName: string): Promise<CodeDescriptionRestDto[]> {
        const response = await this.orderEnumApi.getOrderEnumValuesByClassname(enumClassName);

        return response.data;
    }

    public async getOrderEnumContentForType(pharmacyCode: string | null, enumClassName: string, enumCode: string, typeCode: string): Promise<EnumContentRestDtoModel> {
        const response = await this.orderEnumApi.getOrderEnumContentForType(enumClassName, enumCode, typeCode, null, pharmacyCode);

        return response.data;
    }

    public async updateOrderEnumContentForType(pharmacyCode: string | null, content: MultilingualFieldRestDto, enumClassName: string, enumCode: string, typeCode: string): Promise<void> {
        const updateDto = UpdateEnumContentRestDtoModel.createWithDefaults()
        updateDto.pharmacy_code = pharmacyCode
        updateDto.content = content;

        await this.orderEnumApi.updateOrderEnumContentForType(updateDto, enumClassName, enumCode, typeCode);
    }

    public async updateOrderEnumFallbackContentForType(content: MultilingualFieldRestDto, enumClassName: string, enumCode: string, typeCode: string): Promise<void> {
        const updateDto = UpdateEnumContentRestDtoModel.createWithDefaults()
        updateDto.content = content;

        await this.orderEnumApi.updateOrderEnumFallbackContentForType(updateDto, enumClassName, enumCode, typeCode);
    }


}
