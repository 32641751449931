import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {CatalogProductsCriteriaRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {CatalogProductsCriteriaModel} from "@/models/criteria/CatalogProductsCriteriaModel";

export class CatalogProductsCriteriaRestDtoModel implements CatalogProductsCriteriaRestDto {
    accept?: string | null;

    x_dp_customer_code?: string | null;
    x_dp_language?: string | null;

    page?: number | null;
    page_size?: number | null;

    filter_cnk_code?: number | null;
    filter_active_discount_scheme_id?: string | null;
    filter_category_code?: string | null;
    expect_products_in_subcategory?: boolean | null;
    filter_labo_code?: string | null;
    filter_product_filter_code?: string | null;
    filter_trademark_code?: string | null;
    search_keyword?: string | null;

    constructor(criteria: CatalogProductsCriteriaModel, paging?: PimProductsPagingModel, accept?: string | null) {
        this.filter_cnk_code = criteria.filter_cnk_code;
        this.filter_active_discount_scheme_id = criteria.filter_active_discount_scheme_id;
        this.filter_category_code = criteria.filter_category_code;
        this.expect_products_in_subcategory = criteria.expect_products_in_subcategory;
        this.filter_labo_code = criteria.filter_labo_code;
        this.filter_product_filter_code = criteria.filter_product_filter_code;
        this.filter_trademark_code = criteria.filter_trademark_code;
        this.search_keyword = criteria.search_keyword;

        this.page = paging?.number;
        this.page_size = paging?.size ? parseInt(paging.size) : undefined;

        this.accept = accept;
    }

    public static createWithDefaults(): CatalogProductsCriteriaRestDtoModel {
        return new this(
            CatalogProductsCriteriaModel.createWithDefaults(),
            PimProductsPagingModel.createWithDefaults()
        );
    }
}
