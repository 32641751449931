import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "descriptions"
}
const _hoisted_2 = { class: "description-checked fs-7 fst-italic ms-4 mb-1" }
const _hoisted_3 = { class: "description-unchecked fs-7 fst-italic ms-4" }
const _hoisted_4 = {
  key: 1,
  class: "descriptions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_checkbox_boolean = _resolveComponent("input-checkbox-boolean")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(this.itemName)
  }, [
    _createVNode(_component_input_checkbox_boolean, {
      modelValue: this.item,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.item) = $event)),
      label: _ctx.$t(this.itemDescriptionKey),
      name: this.itemName,
      errors: this.errors,
      disabled: !this.canEditItem
    }, null, 8, ["modelValue", "label", "name", "errors", "disabled"]),
    (this.itemId.includes('medicine'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('descriptionProductChoice' + _ctx.toInitCapitalize(this.itemDescriptionKey) + 'Checked')), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('descriptionProductChoice' + _ctx.toInitCapitalize(this.itemDescriptionKey) + 'Unchecked')), 1)
        ]))
      : _createCommentVNode("", true),
    (this.itemId.includes('is_sync_discounts_to_catalog_active'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", {
            class: _normalizeClass(["description fs-7 fst-italic ms-4 mb-1", {'opacity-50': !this.canEditItem}])
          }, _toDisplayString(_ctx.$t('descriptionProductChoice' + _ctx.toInitCapitalize(this.itemDescriptionKey)) + '.'), 3)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}