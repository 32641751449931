
import {defineComponent} from "vue";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";
import {Exception} from "@/api/interfaces";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {FullProductInfoRestDtoModel} from "@/models/api/pharma-pim-mgmt/FullProductInfoRestDtoModel";
import {useToast} from "vue-toastification";
import {Tooltip} from "bootstrap";
import {EnumClassNamePimEnum} from "@/models/enum/EnumClassNamePimEnum";
import {mapActions} from "vuex";
import {PimMgmtRestService} from "@/services/rest/pim-mgmt/PimMgmtRestService";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import {UtilityHelper} from "@/helpers/UtilityHelper";
import {PimFeedbackRestDtoModel} from "@/models/api/pharma-pim-mgmt/PimFeedbackRestDtoModel";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {CustomerProductPropertyRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import ProductDetailPimTabTextFragmentCard
  from "@/components/layouts/catalog/product-detail/ProductDetailPimTabTextFragmentCard.vue";
import {
  CustomerProductPropertiesResultsRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/CustomerProductPropertiesResultsRestDtoModel";
import {CatalogProductsRestService} from "@/services/rest/cpc-mgmt/CatalogProductsRestService";
import {EnumClassNameCpcEnum} from "@/models/enum/EnumClassNameCpcEnum";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import {I18nContext} from "@/context/I18nContext";
import PimFeedbackPanel from "@/components/layouts/pim/mgmt/PimFeedbackPanel.vue";
import CustomNames from "@/components/layouts/catalog/product-detail/ProductDetailPimTabCustomNames.vue";
import {
  ProductCustomNamesOverviewRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/ProductCustomNamesOverviewRestDtoModel";

export default defineComponent({
  name: "ProductDetailPimTab",
  components: {
    CustomNames,
    PimFeedbackPanel,
    MultilingualSwitcher,
    ProductDetailPimTabTextFragmentCard, BaseCardHeader, BaseCardBody, BaseCard, AlertError,
  },
  emits: ["savedSuccessfully"],
  props: {
    productCode: {
      type: String,
      required: true
    },
    cnkCode: {
      type: String,
      required: false
    }
  },
  computed: {
    UtilityHelper() {
      return UtilityHelper;
    },
    propertyLanguages(): string[] {
      // Merk op, dit wordt énkel opgeroepen in geval van meertalige customers
      // Catalogus is momenteel énkel in nl en fr beschikbaar, daarom hardcoded
      return ['nl', 'fr']
    },
    cnkCodeAsInt(): Number | null {
      if (this.cnkCode) {
        return parseInt(this.cnkCode);
      }
      return null;
    }
  },
  data: () => ({
    exceptions: [] as Exception[],
    tabUI: UIStateDto.createWithDefaults(),

    productInfoNl: FullProductInfoRestDtoModel.createWithDefaults(),

    productName: '',
    customProductName: null as string | null,
    customNames: ProductCustomNamesOverviewRestDtoModel.createWithDefaults(),

    customerPropertiesResult: CustomerProductPropertiesResultsRestDtoModel.createWithDefaults(),
    customerPropertiesLanguageCode: I18nContext.getDefaultApiLanguageKey(),
    isShowFeedbackForFragmentWithId: null as string | null,
    isShowGeneralFeedback: false,


    toaster: useToast(),
    tooltips: [] as Tooltip[],
  }),
  mounted() {
    this.reloadContent();
  },
  methods: {
    ...mapActions('cpc_enum', {findManyEnumByClassnameCPC: "findManyEnumByClassname"}),
    ...mapActions('pim_enum', {findManyEnumByClassnamePIM: "findManyEnumByClassname"}),
    async reloadContent() {
      this.tabUI
        .setNotReady()
        .clearError();

      try {
        const cpcEnumPayload = {
          classNames: [
            EnumClassNameCpcEnum.PRODUCT_PROPERTY_STRATEGY,
          ],
        };

        await Promise.all([
          this.findManyEnumByClassnameCPC(cpcEnumPayload),
          this.findManyEnumByClassnamePIM([
            EnumClassNamePimEnum.TEXT_FRAGMENT_NAME,
            EnumClassNamePimEnum.PIM_PRODUCT_FIELD,
          ]),
          await this.reloadPimContent(),
          await this.reloadCustomerContent(),
          await this.reloadCustomNamesContent(),
        ]);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.tabUI.setReady();
      }
    },
    async switchLanguage(newLangCode: string): Promise<void> {
      this.customerPropertiesLanguageCode = newLangCode;
      await this.reloadContent();

    },
    async customNameSaved(): Promise<void> {
      await this.reloadCustomNamesContent()
      this.closeModal('editCustomProductNameModalId')
    },
    async reloadCustomNamesContent(): Promise<void> {
      this.customNames = await CatalogProductsRestService.getInstance()
        .findProductCustomNames(this.productCode, this.productCode);

      if (this.customerPropertiesLanguageCode == 'nl') {
        this.productName = this.customNames.master_catalog_name.nl ?? '';
        if (this.customNames.custom_name && this.customNames.custom_name.name) {
          this.customProductName = this.customNames.custom_name?.name?.nl ?? null;
        } else {
          this.customProductName = null;
        }
      } else {
        this.productName = this.customNames.master_catalog_name.fr ?? '';
        if (this.customNames.custom_name && this.customNames.custom_name.name) {
          this.customProductName = this.customNames.custom_name?.name?.fr ?? null;
        } else {
          this.customProductName = null;
        }
      }
    },
    async reloadPimContent(): Promise<void> {
      if (this.cnkCodeAsInt) {
        await Promise.all([
          this.productInfoNl = await PimMgmtRestService.getInstance()
            .findMgmtProductInfo(this.cnkCodeAsInt, 'nl', 'PUBLIC_WEBSITE')
        ]);
      }
    },
    async reloadCustomerContent(): Promise<void> {
      if (this.cnkCodeAsInt) {
        await Promise.all([
          this.customerPropertiesResult = await CatalogProductsRestService.getInstance()
            .findCustomerProductProperties(this.productCode, this.customerPropertiesLanguageCode)
        ]);
      }
    },
    async submitPimFeedback(feedback: PimFeedbackRestDtoModel): Promise<void> {
      this.tabUI.clearError();

      try {
        await PimMgmtRestService.getInstance().createPimFeedback(this.cnkCodeAsInt, 'nl', feedback);
        if (feedback.text_fragment_id != null) {
          this.toggleIsShowFeedbackForFragmentWithId(feedback.text_fragment_id);
        } else {
          this.isShowGeneralFeedback = false
        }

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    // helper method to find fragments by their code
    findTextFragmentsByCode(fragmentCode: string) {
      if (this.productInfoNl && this.productInfoNl?.named_text_fragments?.length > 0) {
        return this.productInfoNl?.named_text_fragments?.filter(fragment => fragment?.text_fragment?.code === fragmentCode) || [];
      }
      return [];
    },
    findCustomerPropertyByCode(typeCode: string): CustomerProductPropertyRestDto | null {
      const found = this.customerPropertiesResult.results.filter(prop => prop.type_code === typeCode);
      if (found.length > 0) {
        return found[0];
      }
      return null;
    },
    async submitPimFeedbackCancel(fragmentId: string | null): Promise<void> {
      this.isShowGeneralFeedback = false
      if (fragmentId !== null) {
        this.toggleIsShowFeedbackForFragmentWithId(fragmentId);
      }
    },
    toggleIsShowFeedbackForFragmentWithId(fragmentId: string): void {
      if (this.isShowFeedbackForFragmentWithId === fragmentId) {
        this.isShowFeedbackForFragmentWithId = null;
      } else {
        this.isShowFeedbackForFragmentWithId = fragmentId;
      }
    },
    closeModal(modalId: string): void {
      const modal = document.getElementById(modalId);
      const closeButton = modal?.querySelector('[data-bs-dismiss="modal"]');
      if (closeButton) {
        (closeButton as HTMLElement).click();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.tabUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.tabUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
});
