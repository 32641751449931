<template>
  <base-container>
    <div v-if="AuthContext.isDpUser()">
      <ul class="nav nav-pills mb-4">
        <template v-for="(props, context) in this.debugTree" :key="context">
          <li class="nav-item">
            <a class="nav-link py-2 px-3"
               href="javascript:void(0)"
               data-bs-toggle="tab"
               data-bs-target="#customerContext-tab-pane"
               :class="{
                  active: this.activeTab === context
               }"
               @click="this.activeTab = context"
            >{{ context }}</a>
          </li>
        </template>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link py-2 px-3 fw-700" @click.prevent="download">
            <span class="mas me-1 float-start">download</span>download
          </a>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <template v-for="(props, context) in this.debugTree" :key="context">
          <div class="tab-pane fade"
               :class="{
                  'show active': this.activeTab === context,
               }"
               :id="context + '-tab-pane'"
               role="tabpanel"
               tabindex="0"
          >
            <template v-for="(value, fn) in props" :key="fn">
              <div class="fw-700">{{ fn }}</div>
              <pre @click="UtilityHelper.copyInnerTextToClipboard($event)" title="Click to copy">{{ value }}</pre>
            </template>
          </div>
        </template>
      </div>
    </div>
  </base-container>
</template>

<script>
import {defineComponent} from "vue";
import {AuthContext} from "@/context/AuthContext";
import {CustomerContext} from "@/context/CustomerContext";
import {I18nContext} from "@/context/I18nContext";
import {generateUUIDv4} from "@/helpers/functions/string";
import {UtilityHelper} from "@/helpers/UtilityHelper";
import {AbilityContext} from "@/context/AbilityContext";

export default defineComponent({
  name: "DebugSupport",
  computed: {
    UtilityHelper() {
      return UtilityHelper
    },
    I18nContext() {
      return I18nContext
    },
    CustomerContext() {
      return CustomerContext
    },
    AuthContext() {
      return AuthContext
    }
  },
  data: () => ({
    activeTab: 'CustomerContext',
    now: new Date(),
    debugTree: {
      'CustomerContext': {
        'customerCode': CustomerContext.getCustomerCode(),
        'pharmacyCode': CustomerContext.getPharmacyCode(),
        'mainDomain': CustomerContext.getMainDomain(),
      },
      'AuthContext': {
        'fullName': AuthContext.getFullName(),
        'isDpUser': AuthContext.isDpUser(),
        'isAuthenticated': AuthContext.isAuthenticated(),
        'oauth2Token.expiresAt': AuthContext.oauth2Token.expiresAt,
        'oauth2Token.expiresAtIsoString': AuthContext.oauth2Token.expiresAtIsoString,
        'oauth2Token.idToken': AuthContext.oauth2Token.idToken,
        'oauth2Token.accessToken': AuthContext.oauth2Token.accessToken,
        'oauth2Token.refreshToken': AuthContext.oauth2Token.refreshToken,
        'oauth2Token': AuthContext.oauth2Token,
      },
      'I18nContext': {
        'defaultLanguage': I18nContext.getDefaultLanguage(),
        'languages': I18nContext.getLanguages(),
      },
      'AbilityContext': {
        'licenses': AbilityContext.getLicenses(),
        'features': AbilityContext.getFeatures(),
      },
    }
  }),
  methods: {
    download() {
      const data = JSON.stringify({
        date: this.now.toISOString(),
        debugTree: this.debugTree,
      });
      const blob = new Blob([data], {type: 'text/plain'});
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = generateUUIDv4('dp-debug-') + '.json';
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    }
  }
})
</script>

<style scoped>
pre {
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-left: 2px solid var(--dp-secondary);
  padding-left: 12px;
  margin-top: 6px;
}
</style>
