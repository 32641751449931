/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-PIM Pharmaphoto2 API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { IPrinsLaboRestDto } from '../models';
import { IPrinsPriceOverviewResultsRestDto } from '../models';
import { PagedPp2ProductOverviewResultsRestDto } from '../models';
import { Pp2ProductDetailRestDto } from '../models';
import { Pp2ProductsCriteriaRestDto } from '../models';
/**
 * Pp2ProductAPIApi - axios parameter creator
 * @export
 */
export const Pp2ProductAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the products from PP2.
         * @summary REST.API.PIM.PP2.01 Find PP2 products
         * @param {string} [accept] application/json OR text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
         * @param {string} [filter_belmedis_availability_code] Filters the results based on the belmedis availability.
         * @param {string} [filter_febelco_availability_code] Filters the results based on the febelco availability.
         * @param {string} [filter_photography_status_code] Filters the results based on the photograhpy.
         * @param {string} [filter_iprins_usage_type] Filters the results based on the iprins usage type.
         * @param {string} [filter_category_code_apb] Filters the results to the specified (APB product) category.
         * @param {string} [filter_category_code_apb_legal] Filters the results to the specified (APB product) category.
         * @param {boolean} [filter_is_not_in_internal_category] Filters the results to those that are not associated with an internal category.
         * @param {string} [filter_category_code_internal] Filters the results to the specified (DP managed) category.
         * @param {string} [filter_dp_managed_category_cardinality] Filters the results based on the number of associations found with a (DP managed) category.
         * @param {string} [filter_category_code_dp_managed] Filters the results to the specified (DP managed) category.
         * @param {boolean} [expect_products_in_subcategory] If provided and true, the category filters are interpreted in a way that products in subcategories are inclued.
         * @param {string} [filter_brand_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
         * @param {boolean} [filter_is_image_available] If provided, only filters the results based on the availability of product images.
         * @param {boolean} [filter_is_apb_out_of_business] If true, only returns products that are out of business. If false, only returns products that are NOT out of business.
         * @param {boolean} [filter_is_active] If NOT provided OR if true, only returns products that are not removed.
         * @param {string} [search_keyword] A keyword used as search criterium
         * @param {boolean} [expect_categories] If provided and true, the category info for eacht product is expected in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPp2Products: async (accept?: string|null, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_cnk_code?: number|null, filter_belmedis_availability_code?: string|null, filter_febelco_availability_code?: string|null, filter_photography_status_code?: string|null, filter_iprins_usage_type?: string|null, filter_category_code_apb?: string|null, filter_category_code_apb_legal?: string|null, filter_is_not_in_internal_category?: boolean|null, filter_category_code_internal?: string|null, filter_dp_managed_category_cardinality?: string|null, filter_category_code_dp_managed?: string|null, expect_products_in_subcategory?: boolean|null, filter_brand_code?: string|null, filter_is_image_available?: boolean|null, filter_is_apb_out_of_business?: boolean|null, filter_is_active?: boolean|null, search_keyword?: string|null, expect_categories?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pp2/v1/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (!!page || typeof(page) === "boolean") {
                localVarQueryParameter['page'] = page;
            }

            if (!!page_size || typeof(page_size) === "boolean") {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (!!filter_cnk_code || typeof(filter_cnk_code) === "boolean") {
                localVarQueryParameter['filter_cnk_code'] = filter_cnk_code;
            }

            if (!!filter_belmedis_availability_code || typeof(filter_belmedis_availability_code) === "boolean") {
                localVarQueryParameter['filter_belmedis_availability_code'] = filter_belmedis_availability_code;
            }

            if (!!filter_febelco_availability_code || typeof(filter_febelco_availability_code) === "boolean") {
                localVarQueryParameter['filter_febelco_availability_code'] = filter_febelco_availability_code;
            }

            if (!!filter_photography_status_code || typeof(filter_photography_status_code) === "boolean") {
                localVarQueryParameter['filter_photography_status_code'] = filter_photography_status_code;
            }

            if (!!filter_iprins_usage_type || typeof(filter_iprins_usage_type) === "boolean") {
                localVarQueryParameter['filter_iprins_usage_type'] = filter_iprins_usage_type;
            }

            if (!!filter_category_code_apb || typeof(filter_category_code_apb) === "boolean") {
                localVarQueryParameter['filter_category_code_apb'] = filter_category_code_apb;
            }

            if (!!filter_category_code_apb_legal || typeof(filter_category_code_apb_legal) === "boolean") {
                localVarQueryParameter['filter_category_code_apb_legal'] = filter_category_code_apb_legal;
            }

            if (!!filter_is_not_in_internal_category || typeof(filter_is_not_in_internal_category) === "boolean") {
                localVarQueryParameter['filter_is_not_in_internal_category'] = filter_is_not_in_internal_category;
            }

            if (!!filter_category_code_internal || typeof(filter_category_code_internal) === "boolean") {
                localVarQueryParameter['filter_category_code_internal'] = filter_category_code_internal;
            }

            if (!!filter_dp_managed_category_cardinality || typeof(filter_dp_managed_category_cardinality) === "boolean") {
                localVarQueryParameter['filter_dp_managed_category_cardinality'] = filter_dp_managed_category_cardinality;
            }

            if (!!filter_category_code_dp_managed || typeof(filter_category_code_dp_managed) === "boolean") {
                localVarQueryParameter['filter_category_code_dp_managed'] = filter_category_code_dp_managed;
            }

            if (!!expect_products_in_subcategory || typeof(expect_products_in_subcategory) === "boolean") {
                localVarQueryParameter['expect_products_in_subcategory'] = expect_products_in_subcategory;
            }

            if (!!filter_brand_code || typeof(filter_brand_code) === "boolean") {
                localVarQueryParameter['filter_brand_code'] = filter_brand_code;
            }

            if (!!filter_is_image_available || typeof(filter_is_image_available) === "boolean") {
                localVarQueryParameter['filter_is_image_available'] = filter_is_image_available;
            }

            if (!!filter_is_apb_out_of_business || typeof(filter_is_apb_out_of_business) === "boolean") {
                localVarQueryParameter['filter_is_apb_out_of_business'] = filter_is_apb_out_of_business;
            }

            if (!!filter_is_active || typeof(filter_is_active) === "boolean") {
                localVarQueryParameter['filter_is_active'] = filter_is_active;
            }

            if (!!search_keyword || typeof(search_keyword) === "boolean") {
                localVarQueryParameter['search_keyword'] = search_keyword;
            }

            if (!!expect_categories || typeof(expect_categories) === "boolean") {
                localVarQueryParameter['expect_categories'] = expect_categories;
            }

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the IPrins labo info of the labo that produced the product.
         * @summary REST.API.PIM.IPR.02 Retrieve the IPrins labo info of the labo that produced the product
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIPrinsProductManufacturer: async (cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cnk_code' is not null or undefined
            if (cnk_code === null || cnk_code === undefined) {
                throw new RequiredError('cnk_code','Required parameter cnk_code was null or undefined when calling getIPrinsProductManufacturer.');
            }
            const localVarPath = `/pp2/v1/product/{cnk_code}/iprins/labo`
                .replace(`{${"cnk_code"}}`, encodeURIComponent(String(cnk_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the IPrins prices of the product.
         * @summary REST.API.PIM.IPR.01 Retrieve the IPrins prices of the product
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIPrinsProductPrices: async (cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cnk_code' is not null or undefined
            if (cnk_code === null || cnk_code === undefined) {
                throw new RequiredError('cnk_code','Required parameter cnk_code was null or undefined when calling getIPrinsProductPrices.');
            }
            const localVarPath = `/pp2/v1/product/{cnk_code}/iprins/price`
                .replace(`{${"cnk_code"}}`, encodeURIComponent(String(cnk_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the product details.
         * @summary REST.API.PIM.PP2.02 Retrieve PP2 product details
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPp2ProductForCnkCode: async (cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cnk_code' is not null or undefined
            if (cnk_code === null || cnk_code === undefined) {
                throw new RequiredError('cnk_code','Required parameter cnk_code was null or undefined when calling getPp2ProductForCnkCode.');
            }
            const localVarPath = `/pp2/v1/product/{cnk_code}`
                .replace(`{${"cnk_code"}}`, encodeURIComponent(String(cnk_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Pp2ProductAPIApi - functional programming interface
 * @export
 */
export const Pp2ProductAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the products from PP2.
         * @summary REST.API.PIM.PP2.01 Find PP2 products
         * @param {string} [accept] application/json OR text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
         * @param {string} [filter_belmedis_availability_code] Filters the results based on the belmedis availability.
         * @param {string} [filter_febelco_availability_code] Filters the results based on the febelco availability.
         * @param {string} [filter_photography_status_code] Filters the results based on the photograhpy.
         * @param {string} [filter_iprins_usage_type] Filters the results based on the iprins usage type.
         * @param {string} [filter_category_code_apb] Filters the results to the specified (APB product) category.
         * @param {string} [filter_category_code_apb_legal] Filters the results to the specified (APB product) category.
         * @param {boolean} [filter_is_not_in_internal_category] Filters the results to those that are not associated with an internal category.
         * @param {string} [filter_category_code_internal] Filters the results to the specified (DP managed) category.
         * @param {string} [filter_dp_managed_category_cardinality] Filters the results based on the number of associations found with a (DP managed) category.
         * @param {string} [filter_category_code_dp_managed] Filters the results to the specified (DP managed) category.
         * @param {boolean} [expect_products_in_subcategory] If provided and true, the category filters are interpreted in a way that products in subcategories are inclued.
         * @param {string} [filter_brand_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
         * @param {boolean} [filter_is_image_available] If provided, only filters the results based on the availability of product images.
         * @param {boolean} [filter_is_apb_out_of_business] If true, only returns products that are out of business. If false, only returns products that are NOT out of business.
         * @param {boolean} [filter_is_active] If NOT provided OR if true, only returns products that are not removed.
         * @param {string} [search_keyword] A keyword used as search criterium
         * @param {boolean} [expect_categories] If provided and true, the category info for eacht product is expected in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPp2Products(_axios: AxiosInstance, accept?: string|null, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_cnk_code?: number|null, filter_belmedis_availability_code?: string|null, filter_febelco_availability_code?: string|null, filter_photography_status_code?: string|null, filter_iprins_usage_type?: string|null, filter_category_code_apb?: string|null, filter_category_code_apb_legal?: string|null, filter_is_not_in_internal_category?: boolean|null, filter_category_code_internal?: string|null, filter_dp_managed_category_cardinality?: string|null, filter_category_code_dp_managed?: string|null, expect_products_in_subcategory?: boolean|null, filter_brand_code?: string|null, filter_is_image_available?: boolean|null, filter_is_apb_out_of_business?: boolean|null, filter_is_active?: boolean|null, search_keyword?: string|null, expect_categories?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedPp2ProductOverviewResultsRestDto>> {
            const localVarAxiosArgs = await Pp2ProductAPIApiAxiosParamCreator(configuration).findPp2Products(accept, x_dp_customer_code, x_dp_language, page, page_size, filter_cnk_code, filter_belmedis_availability_code, filter_febelco_availability_code, filter_photography_status_code, filter_iprins_usage_type, filter_category_code_apb, filter_category_code_apb_legal, filter_is_not_in_internal_category, filter_category_code_internal, filter_dp_managed_category_cardinality, filter_category_code_dp_managed, expect_products_in_subcategory, filter_brand_code, filter_is_image_available, filter_is_apb_out_of_business, filter_is_active, search_keyword, expect_categories, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the IPrins labo info of the labo that produced the product.
         * @summary REST.API.PIM.IPR.02 Retrieve the IPrins labo info of the labo that produced the product
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIPrinsProductManufacturer(_axios: AxiosInstance, cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPrinsLaboRestDto>> {
            const localVarAxiosArgs = await Pp2ProductAPIApiAxiosParamCreator(configuration).getIPrinsProductManufacturer(cnk_code, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the IPrins prices of the product.
         * @summary REST.API.PIM.IPR.01 Retrieve the IPrins prices of the product
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIPrinsProductPrices(_axios: AxiosInstance, cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPrinsPriceOverviewResultsRestDto>> {
            const localVarAxiosArgs = await Pp2ProductAPIApiAxiosParamCreator(configuration).getIPrinsProductPrices(cnk_code, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the product details.
         * @summary REST.API.PIM.PP2.02 Retrieve PP2 product details
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPp2ProductForCnkCode(_axios: AxiosInstance, cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pp2ProductDetailRestDto>> {
            const localVarAxiosArgs = await Pp2ProductAPIApiAxiosParamCreator(configuration).getPp2ProductForCnkCode(cnk_code, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * Pp2ProductAPIApi - object-oriented interface
 * @export
 * @class Pp2ProductAPIApi
 * @extends {BaseAPI}
 */
export class Pp2ProductAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for retrieving the products from PP2.
     * @summary REST.API.PIM.PP2.01 Find PP2 products
     * @param {string} [accept] application/json OR text/csv
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {number} [page] Specifies the page number that is expected of the paged result.
     * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
     * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
     * @param {string} [filter_belmedis_availability_code] Filters the results based on the belmedis availability.
     * @param {string} [filter_febelco_availability_code] Filters the results based on the febelco availability.
     * @param {string} [filter_photography_status_code] Filters the results based on the photograhpy.
     * @param {string} [filter_iprins_usage_type] Filters the results based on the iprins usage type.
     * @param {string} [filter_category_code_apb] Filters the results to the specified (APB product) category.
     * @param {string} [filter_category_code_apb_legal] Filters the results to the specified (APB product) category.
     * @param {boolean} [filter_is_not_in_internal_category] Filters the results to those that are not associated with an internal category.
     * @param {string} [filter_category_code_internal] Filters the results to the specified (DP managed) category.
     * @param {string} [filter_dp_managed_category_cardinality] Filters the results based on the number of associations found with a (DP managed) category.
     * @param {string} [filter_category_code_dp_managed] Filters the results to the specified (DP managed) category.
     * @param {boolean} [expect_products_in_subcategory] If provided and true, the category filters are interpreted in a way that products in subcategories are inclued.
     * @param {string} [filter_brand_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
     * @param {boolean} [filter_is_image_available] If provided, only filters the results based on the availability of product images.
     * @param {boolean} [filter_is_apb_out_of_business] If true, only returns products that are out of business. If false, only returns products that are NOT out of business.
     * @param {boolean} [filter_is_active] If NOT provided OR if true, only returns products that are not removed.
     * @param {string} [search_keyword] A keyword used as search criterium
     * @param {boolean} [expect_categories] If provided and true, the category info for eacht product is expected in the result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Pp2ProductAPIApi
     */
     private findPp2Products(accept?: string|null, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_cnk_code?: number|null, filter_belmedis_availability_code?: string|null, filter_febelco_availability_code?: string|null, filter_photography_status_code?: string|null, filter_iprins_usage_type?: string|null, filter_category_code_apb?: string|null, filter_category_code_apb_legal?: string|null, filter_is_not_in_internal_category?: boolean|null, filter_category_code_internal?: string|null, filter_dp_managed_category_cardinality?: string|null, filter_category_code_dp_managed?: string|null, expect_products_in_subcategory?: boolean|null, filter_brand_code?: string|null, filter_is_image_available?: boolean|null, filter_is_apb_out_of_business?: boolean|null, filter_is_active?: boolean|null, search_keyword?: string|null, expect_categories?: boolean|null, options?: any) {
        return Pp2ProductAPIApiFp(this.configuration).findPp2Products(this.axios, accept, x_dp_customer_code, x_dp_language, page, page_size, filter_cnk_code, filter_belmedis_availability_code, filter_febelco_availability_code, filter_photography_status_code, filter_iprins_usage_type, filter_category_code_apb, filter_category_code_apb_legal, filter_is_not_in_internal_category, filter_category_code_internal, filter_dp_managed_category_cardinality, filter_category_code_dp_managed, expect_products_in_subcategory, filter_brand_code, filter_is_image_available, filter_is_apb_out_of_business, filter_is_active, search_keyword, expect_categories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the products from PP2.
     * @summary REST.API.PIM.PP2.01 Find PP2 products
     * @param {Pp2ProductsCriteriaRestDto} [dto] The CriteriaRestDto for the api call.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Pp2ProductAPIApi
     */
    public findPp2ProductsByPp2ProductsCriteriaRestDto(dto: Pp2ProductsCriteriaRestDto, options?: any){
        return this.findPp2Products(dto?.accept, dto?.x_dp_customer_code, dto?.x_dp_language, dto?.page, dto?.page_size, dto?.filter_cnk_code, dto?.filter_belmedis_availability_code, dto?.filter_febelco_availability_code, dto?.filter_photography_status_code, dto?.filter_iprins_usage_type, dto?.filter_category_code_apb, dto?.filter_category_code_apb_legal, dto?.filter_is_not_in_internal_category, dto?.filter_category_code_internal, dto?.filter_dp_managed_category_cardinality, dto?.filter_category_code_dp_managed, dto?.expect_products_in_subcategory, dto?.filter_brand_code, dto?.filter_is_image_available, dto?.filter_is_apb_out_of_business, dto?.filter_is_active, dto?.search_keyword, dto?.expect_categories, options);
    }
    /**
     * API endpoint responsible for retrieving the IPrins labo info of the labo that produced the product.
     * @summary REST.API.PIM.IPR.02 Retrieve the IPrins labo info of the labo that produced the product
     * @param {number} cnk_code The product CNK code that uniquely identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Pp2ProductAPIApi
     */
     public getIPrinsProductManufacturer(cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return Pp2ProductAPIApiFp(this.configuration).getIPrinsProductManufacturer(this.axios, cnk_code, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the IPrins prices of the product.
     * @summary REST.API.PIM.IPR.01 Retrieve the IPrins prices of the product
     * @param {number} cnk_code The product CNK code that uniquely identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Pp2ProductAPIApi
     */
     public getIPrinsProductPrices(cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return Pp2ProductAPIApiFp(this.configuration).getIPrinsProductPrices(this.axios, cnk_code, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the product details.
     * @summary REST.API.PIM.PP2.02 Retrieve PP2 product details
     * @param {number} cnk_code The product CNK code that uniquely identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Pp2ProductAPIApi
     */
     public getPp2ProductForCnkCode(cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return Pp2ProductAPIApiFp(this.configuration).getPp2ProductForCnkCode(this.axios, cnk_code, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

}
