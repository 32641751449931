/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 * Generated: Thu, 22 Jun 2023 14:55:31 +0000
 */
export enum ReferenceTypeEnum {
    PAGE = 'PAGE',
    MENU = 'MENU',
}