import {Context} from "@/patterns/Context";
import {ProductFeatureAuthorizationRestDtoModel} from "@/models/ProductFeatureAuthorizationRestDtoModel";
import {AuthContext} from "@/context/AuthContext";
import {shallowReactive} from "vue";

export class AbilityContext extends Context {
    private static isInitialized: boolean = false;

    private static productFeatureAuthorizations: ProductFeatureAuthorizationRestDtoModel[];
    private static features: Map<string, boolean> = shallowReactive(new Map<string, boolean>());
    private static licenses: Map<string, boolean> = shallowReactive(new Map<string, boolean>());

    public static setProductFeatureAuthorizations(productFeatureAuthorizations: ProductFeatureAuthorizationRestDtoModel[]): void {
        this.isInitialized = true;
        this.productFeatureAuthorizations = productFeatureAuthorizations;
        this.parse();
    }

    public static getIsInitialized(): boolean {
        return this.isInitialized;
    }

    public static getFeatures(): string[] {
        return [...this.features.keys()].sort();
    }

    public static getLicenses(): string[] {
        return [...this.licenses.keys()].sort();
    }

    public static getLicensesAsDescription(): string[] {
        const descriptions = this.productFeatureAuthorizations.map((productFeatureAuthorization: ProductFeatureAuthorizationRestDtoModel) => {
            return productFeatureAuthorization.product ? (productFeatureAuthorization.product?.description ?? '') : '';
        });
        // filter only uniques
        return descriptions.filter((description: string, i: number) => {
            return descriptions.indexOf(description) === i;
        })
    }

    public static isAuthorizedForLicense(license: string): boolean {
        return this.licenses.has(license);
    }

    public static isAuthorizedForFeature(feature: string): boolean {
        return this.features.has(feature);
    }

    public static isNotAuthorizedForFeature(feature: string): boolean {
        return !this.features.has(feature);
    }

    public static isAuthorizedForAnyFeature(...features: string[]): boolean {
        let res: boolean = false;
        features.forEach((feature: string) => {
            if (this.isAuthorizedForFeature(feature)) {
                res = true;
            }
        });

        return res;
    }

    public static isAuthorizedForAllFeatures(...features: string[]): boolean {
        let res: boolean = true;
        features.forEach((feature: string) => {
            if (!this.isAuthorizedForFeature(feature)) {
                res = false;
            }
        });

        return res;
    }

    public static isAuthorizedForAnyLicense(...licenses: string[]): boolean {
        let res: boolean = false;
        licenses.forEach((license: string) => {
            if (this.isAuthorizedForLicense(license)) {
                res = true;
            }
        });

        return res;
    }

    protected static parse(): void {
        this.features.clear();
        this.licenses.clear();

        this.productFeatureAuthorizations.forEach((authorization: ProductFeatureAuthorizationRestDtoModel) => {
            if (authorization.product !== undefined && authorization.product !== null) {
                this.licenses.set(authorization.product.code, true);
            }

            if (authorization.is_dp_user_feature === undefined
                || authorization.is_dp_user_feature === null
                || !authorization.is_dp_user_feature
                || AuthContext.isDpUser()
            ) {
                this.features.set(authorization.feature.code, true);
            }
        });
    }
}
