
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {CodeDescriptionRestDto} from "@/api/pharma-order-settings/models";
import {DeliveryMethodsRestService} from "@/services/rest/settings/DeliveryMethodsRestService";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import {useToast} from "vue-toastification";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";
import {EnumClassNameOrderEnum} from "@/models/enum/EnumClassNameOrderEnum";
import {mapMutations} from "vuex";
import {AbilityContext} from "@/context/AbilityContext";
import {usePharmacyStore} from "@/stores/PharmacyStore";
import {Tab} from "bootstrap";

export default defineComponent({
  name: "DeliveryMethodTexts",
  components: {
    BaseContainer,
    BaseTitle,
    AlertError2,
  },
  computed: {
    AbilityContext() {
      return AbilityContext;
    }
  },
  setup() {
    const pharmacyStore = usePharmacyStore();

    return {
      pharmacyStore,
    };
  },
  data() {
    return {
      activeTab: '' as string,
      supportedMethods: [] as CodeDescriptionRestDto[],
      types: [] as CodeDescriptionRestDto[],

      toaster: useToast(),
      pageUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();
    this.pageUI.setReady();
  },
  methods: {
    ...mapMutations('order_enum', ['setEnumerationOverviewModel']),
    async reloadContent(): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        if (AbilityContext.isAuthorizedForFeature('MGMT_FALLBACK_CONTENT')) {
          this.supportedMethods = await DeliveryMethodsRestService.getInstance().findAuthorizedDeliveryMethods();
        } else {
          this.supportedMethods = await DeliveryMethodsRestService.getInstance().findSupportedDeliveryMethods();
        }

        this.types = await EnumRestService.getInstance().getOrderEnumValuesByClassname(EnumClassNameOrderEnum.ENUMERATION_CONTENT_TYPE);

        const selectedDeliveryTypeCode = this.pharmacyStore.deliveryMethodEnumContent.selectedDeliveryTypeCode;
        this.activeTab = selectedDeliveryTypeCode ?? this.supportedMethods[0]?.code ?? '';
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    goToDetail(deliveryMethodCode: string, typeCode: string): object {
      if (AbilityContext.isAuthorizedForFeature('MGMT_FALLBACK_CONTENT')) {
        return {
          name: 'edit-delivery-method-fallback-text',
          params: {
            delivery_method_code: deliveryMethodCode,
            type_code: typeCode,
          }
        };
      } else {
        return {
          name: 'edit-delivery-method-text',
          params: {
            delivery_method_code: deliveryMethodCode,
            type_code: typeCode,
          }
        };
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    },
    showTab(code: string) {
      this.pharmacyStore.deliveryMethodEnumContent.selectedDeliveryTypeCode = code;
      this.activeTab = code;
    },
  }
})
