import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_alert_success = _resolveComponent("alert-success")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_DiscountsPriceListsOverview = _resolveComponent("DiscountsPriceListsOverview")!
  const _component_base_container = _resolveComponent("base-container")!
  const _component_CatalogBuildStatus = _resolveComponent("CatalogBuildStatus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_container, { "has-build-status-on-page": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseTitle, {
          title: `${_ctx.$t('discounts')} ${_ctx.$t('and')} ${_ctx.$t('priceLists').toLowerCase()}`,
          "help-text": _ctx.$t('descriptionDiscountsAndPriceLists'),
          "show-refresh-button": "",
          onRefresh: this.reloadContent
        }, null, 8, ["title", "help-text", "onRefresh"]),
        _createVNode(_component_alert_success, {
          "show-if": this.savedSuccessfully,
          class: "mb-3"
        }, null, 8, ["show-if"]),
        _createVNode(_component_alert_error, {
          exceptions: this.exceptions,
          class: "mb-3"
        }, null, 8, ["exceptions"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_DiscountsPriceListsOverview, {
              type: "DISCOUNT",
              "is-ready": _ctx.isReadyDiscountSchemes,
              class: "mb-5",
              onMoveDiscountScheme: _ctx.moveDiscountScheme,
              onDeleteDiscountScheme: _ctx.deleteDiscountScheme
            }, null, 8, ["is-ready", "onMoveDiscountScheme", "onDeleteDiscountScheme"]),
            _createVNode(_component_DiscountsPriceListsOverview, {
              type: "PRICELIST",
              "is-ready": _ctx.isReadyPriceLists,
              onMovePriceList: _ctx.movePriceList,
              onDeletePriceList: _ctx.deletePriceList
            }, null, 8, ["is-ready", "onMovePriceList", "onDeletePriceList"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_CatalogBuildStatus)
  ], 64))
}