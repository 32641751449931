
import {defineComponent} from 'vue'
import ItemIcons from "@/components/layouts/pim/PimProductsOverviewItemIcons.vue";
import {CatalogProductOverviewRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductOverviewRestDtoModel";

export default defineComponent({
  name: "CatalogProductsOverviewTableItem",
  components: {ItemIcons},
  props: {
    product: {
      type: [CatalogProductOverviewRestDtoModel, Object],
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    openProductDetail(): void {
      this.$router.push({name: 'catalog-products-detail', params: {product_code: this.product.product_code}})
    },
  }
})
