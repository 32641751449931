
import {defineComponent, PropType} from "vue";
import {mapActions, mapGetters} from "vuex";
import {FullMenuItemRestDto,} from "@/api/pharma-cms-menu/models";
import {stringHasContent} from "@/helpers/functions/string";
import MenusOverview from "@/components/layouts/menus/MenusOverview.vue";
import PageLinkPicker from "@/components/UI/PageLinkPicker.vue";
import {PageLinkPayload} from "@/models/payload/PageLinkPayload";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {DpException} from "@/exception/DpException";
import {StatusEnum} from "@/api/enums/status-enum";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {AbilityContext} from "@/context/AbilityContext";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {MenuItemRestDtoModel} from "@/models/api/pharma-cms-menu/MenuItemRestDtoModel";
import {MenuRestService} from "@/services/rest/cms-menu/MenuRestService";
import {TargetEnum} from "@/api/enums/target-enum";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {PageReferenceRestDtoModel} from "@/models/api/pharma-cms-menu/PageReferenceRestDtoModel";
import {ContentPageReferenceRestDtoModel} from "@/models/api/pharma-cms-menu/ContentPageReferenceRestDtoModel";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputText from "@/components/UI/InputText.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import {TinyEmitter} from "tiny-emitter";
import VisibilityRule from "@/components/layouts/VisibilityRule.vue";
import {ContentVisibilityRuleRestDtoModel} from "@/models/api/pharma-cms-menu/ContentVisibilityRuleRestDtoModel";

export default defineComponent({
  name: "MenuItemsDetail",
  components: {
    VisibilityRule,
    InputSelect, InputCheckboxBoolean, InputText, BaseSpinner, AlertError2, BaseTitle, PageLinkPicker, MenusOverview
  },
  props: {
    mode: {
      type: String as PropType<PageDetailModeEnum>,
      required: true,
    },
    isSubMenu: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      bus: new TinyEmitter(),

      menuId: this.$route.params.menu_id as string,
      menuItemId: this.$route.params.menu_item_id as string,
      slotCode: this.$route.params.slot_code as string,

      menuItem: MenuItemRestDtoModel.createWithDefaults(),
      menuItemUI: UIStateDto.createWithDefaults(),

      hasSubMenu: false as boolean,
      subMenuItems: [] as FullMenuItemRestDto[],

      pageLinkPayload: PageLinkPayload.createWithDefaults(),

      statusOptions: [
        {code: StatusEnum.PUBLISHED, description: this.$t('published')},
        {code: StatusEnum.NOT_PUBLISHED, description: this.$t('notPublished')},
      ] as CodeDescriptionRestDto[]
    }
  },
  mounted() {
    this.bus.on('clearExceptions', this.clearExceptions);
    this.bus.on('reload', this.reloadContent);
    this.bus.on('setExceptions', this.setExceptions);
    this.bus.on('setIsReady', this.setIsReady);

    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_enum', ['getEnumByClassname']),
    ...mapGetters('cpc_mgmt', ["getProductFilterOverview"]),
    AbilityContext() {
      return AbilityContext
    },
    PageDetailModeEnum() {
      return PageDetailModeEnum;
    },
    goBackRoute(): object {
      return this.isSubMenu
        ? {
          name: 'edit-menu-item',
          params: {slot_code: this.slotCode, menu_id: this.menuId, menu_item_id: this.menuItemId}
        }
        : {
          name: 'edit-menu',
          params: {slot_code: this.slotCode}
        };
    },
    hasSubMenuId(): boolean {
      return stringHasContent(this.menuItem.submenu_id);
    },
    hasVisibilityRule(): boolean {
      return !!this.menuItem.visibility_rule;
    }
  },
  methods: {
    ...mapActions('cpc_mgmt', ["searchProductFilterOverview"]),
    ...mapActions('cms_enum', ["findEnumByClassname"]),
    addVisibilityRule(): void {
      if (!this.hasVisibilityRule) {
        this.menuItem.visibility_rule = ContentVisibilityRuleRestDtoModel.createWithDefaults();
      }
    },
    deleteVisibilityRule(): void {
      if (this.hasVisibilityRule) {
        this.menuItem.visibility_rule = undefined;
      }
    },
    onVisibilityRuleChanged(payload: ContentVisibilityRuleRestDtoModel): void {
      this.menuItem.visibility_rule = payload;
    },

    onPageLinkPickerChanged(payload: PageLinkPayload): void {
      // console.log('onPageLinkPickerChanged: ', payload)
      if (payload.type === PageLinkTypeEnum.NONE) {
        throw [new DpException('PageLinkType NONE is not possible in this context.')];
      } else if (payload.type === PageLinkTypeEnum.URL) {
        this.menuItem.page_reference = new PageReferenceRestDtoModel(
          null,
          payload.external_url,
          payload.target as TargetEnum
        );
      } else if (payload.type === PageLinkTypeEnum.PAGE) {
        this.menuItem.page_reference = new PageReferenceRestDtoModel(
          new ContentPageReferenceRestDtoModel(payload.page_id, null),
          null,
          payload.target as TargetEnum
        );
      }
    },
    onPageLinkPickerChangedText(text: string): void {
      // console.log('onPageLinkPickerTextChanged: ', text)
      this.menuItem.menu_item_text = text;
    },

    async deleteItem(): Promise<void> {
      // console.log(`Deleting menu item with ID ${this.menuItemId}`)
      this.menuItemUI.clearError();

      try {
        const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
          title: 'Menu item verwijderen',
          body: this.$t('deleteConfirmation'),
          confirmButtonText: this.$t('delete'),
          confirmButtonClass: 'btn-danger',
        });

        onConfirm(async () => {
          this.menuItemUI.setNotReady();

          await MenuRestService.getInstance().deleteMenuItem(this.menuItemId);

          await handleSavedSuccessfully(this.$t('deletedSuccessfully'));
          await this.$router.back();
        });

        await reveal();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.menuItemUI.setReady();
      }
    },
    async reloadContent(): Promise<void> {
      this.menuItemUI
        .clearError()
        .setNotReady();

      try {
        const promises = [this.findEnumByClassname(EnumClassNameCmsEnum.CONTENT_VISIBILITY_RULE)] as any[];

        if (this.mode === PageDetailModeEnum.EDIT) {
          promises.push(
            this.menuItem = await MenuRestService.getInstance().findMenuById(this.menuItemId)
          );
        }
        if (AbilityContext.isAuthorizedForFeature('USE_NAMED_PRODUCT_FILTER')) {
          promises.push(
            this.searchProductFilterOverview({
              is_named: true,
              is_precalculated: true
            })
          );
        }

        await Promise.all(promises);

        this.hasSubMenu = (!this.isSubMenu && this.hasSubMenuId);
        console.log("hasSubMenu", this.hasSubMenu)
        if (this.hasSubMenu) {
          await this.searchSubMenu();
        }

        this.pageLinkPayload = new PageLinkPayload(
          this.menuItem.page_reference?.external_url ?? null,
          this.menuItem.page_reference?.internal_page_reference?.page_id ?? null,
          this.menuItem.page_reference?.target ?? TargetEnum.SELF
        );
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.menuItemUI.setReady();
      }
    },
    async searchSubMenu() {
      this.subMenuItems = await MenuRestService.getInstance().findMenuItemsById(this.menuItem.submenu_id);
    },
    async submitData(): Promise<void> {
      this.menuItemUI.clearError();

      try {
        if (this.mode === PageDetailModeEnum.NEW) {
          await MenuRestService.getInstance().createMenuItem(
            this.menuItem,
            this.menuId,
            this.hasSubMenu,
          )
        } else {
          await MenuRestService.getInstance().updateMenuItem(
            this.menuItem,
            this.menuItemId
          )
        }

        await handleSavedSuccessfully();

        if (this.mode === PageDetailModeEnum.NEW) {
          await this.$router.back();
        } else {
          window.scrollTo(0, 0);
          await this.reloadContent();
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.menuItemUI.setReady();
      }
    },
    clearExceptions(): void {
      // console.log("clearExceptions")
      this.menuItemUI.clearError;
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.menuItemUI as UIStateDto))
        .catch(exceptions, true);
    },
    setIsReady(isReady: boolean): void {
      this.menuItemUI.setIsReady(isReady);
    }
  }
});
