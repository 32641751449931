
import {defineComponent} from "vue";
import store from "@/store";
import {mapGetters} from 'vuex';
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import {LibraryStructureTypeEnum} from "@/models/enum/LibraryStructureTypeEnum";
import {AuthContext} from "@/context/AuthContext";
import {AbilityContext} from "@/context/AbilityContext";
import CustomerChanger from "@/components/UI/CustomerChanger.vue";
import TheSideBarSubMenuItem from "@/components/layouts/TheSideBarSubMenuItem.vue";
import TheSideBarMenuItem from "@/components/layouts/TheSideBarMenuItem.vue";
import {AppContext} from "@/context/AppContext";
import {DIKeyEnum} from "@/di/DIKeyEnum";

export default defineComponent({
  components: {
    CustomerChanger,
    MenuItem: TheSideBarMenuItem,
    SubMenuItem: TheSideBarSubMenuItem,
  },
  inject: {
    'config': DIKeyEnum.CONFIG,
  },
  computed: {
    AppContext() {
      return AppContext
    },
    ...mapGetters(['getUser', 'isAuthenticated']),
    AuthContext() {
      return AuthContext
    },
    AbilityContext() {
      return AbilityContext
    },
    LibraryStructureTypeEnum() {
      return LibraryStructureTypeEnum
    },
    ContentBlockTypesEnum() {
      return ContentBlockTypesEnum
    },
  },
  methods: {
    async login(): Promise<void> {
      await store.dispatch('login');
    },
    logout(): void {
      store.dispatch('logout');
      this.$router.replace({name: 'home'});
    },
    toggleMenu(): void {
      const buttons = document.getElementsByClassName('btn-toggle');
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].classList.add('collapsed')
        buttons[i].setAttribute("aria-expanded", "false");
      }
      const content = document.getElementsByClassName('hidden');
      for (let i = 0; i < content.length; i++) {
        content[i].classList.remove('show')
      }
    }
  },
});
