import {CpcEnumEndpointsApiService} from "@/main";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-enum/models";
import {DpException} from "@/exception/DpException";
import {EnumClassNameCpcEnum} from "@/models/enum/EnumClassNameCpcEnum";

interface findEnumByClassNamePayload {
    className: EnumClassNameCpcEnum;
    folder?: string;
}

interface findEnumByClassNamesPayload {
    classNames: EnumClassNameCpcEnum[];
    folder?: string;
}

export default {
    namespaced: true,
    state() {
        return {
            enums: new Map<EnumClassNameCpcEnum, CodeDescriptionRestDto[]>(),
        };
    },
    actions: {
        async findEnumByClassname(context: any, payload: findEnumByClassNamePayload): Promise<void> {
            if (!context.state.enums.has(payload.className)) {
                const response = await CpcEnumEndpointsApiService
                    .getCpcEnumValuesByClassname(`App\\Model\\Enum${payload.folder ? '\\' + payload.folder : ''}\\${payload.className}`);
                context.state.enums.set(payload.className, response.data as CodeDescriptionRestDto[]);
            }
        },
        async findManyEnumByClassname(context: any, payload: findEnumByClassNamesPayload): Promise<void> {
            const promises = [] as Promise<void>[];

            payload.classNames.forEach((className: EnumClassNameCpcEnum) => {
                promises.push(context.dispatch("findEnumByClassname", {
                    className: className,
                    folder: payload.folder
                } as findEnumByClassNamePayload));
            });

            await Promise.all(promises);
        },
    },
    mutations: {},
    getters: {
        getEnumByClassname: (state: any) => (className: EnumClassNameCpcEnum): CodeDescriptionRestDto[] => {
            if (!state.enums.has(className)) {
                throw new DpException('Enum `' + className + '` has not been initialized, yet.');
            }

            return state.enums.get(className);
        },
        getEnumByClassnameOrDefault: (state: any, getters: any) => (className: EnumClassNameCpcEnum, defaultValue: any): CodeDescriptionRestDto[] | any => {
            try {
                return getters.getEnumByClassname(className);
            } catch (exceptions: any) {
                return defaultValue;
            }
        },
        getCodeDescriptionByCodeFromEnumByClassname: (state: any, getters: any) => (className: EnumClassNameCpcEnum, code: string): CodeDescriptionRestDto | null => {
            return getters.getEnumByClassname(className).find((cd: CodeDescriptionRestDto) => {
                return cd.code === code;
            }) ?? null;
        },
    },
};
