import {
    BackgroundImageRestDto, cloneBackgroundImageRestDtoMetaData,
    cloneContentPageRowRestDtoMetaData,
    ContentPageRowRestDto, DeviceVisibilityRestDto
} from "@/api/pharma-cms-content-page/models";
import {BackgroundColorEnum} from "@/api/enums/background-color-enum";
import {ContainerTypeEnum} from "@/api/enums/container-type-enum";
import {HorizontalGutterEnum} from "@/api/enums/horizontal-gutter-enum";
import {ItemsHorizontalAlignmentEnum} from "@/api/enums/items-horizontal-alignment-enum";
import {ItemsVerticalAlignmentEnum} from "@/api/enums/items-vertical-alignment-enum";
import {MarginBottomEnum} from "@/api/enums/margin-bottom-enum";
import {MarginTopEnum} from "@/api/enums/margin-top-enum";
import {PaddingBottomEnum} from "@/api/enums/padding-bottom-enum";
import {PaddingTopEnum} from "@/api/enums/padding-top-enum";
import {BorderRadiusRestDtoModel} from "@/models/BorderRadiusRestDtoModel";
import {ColorRestDtoModel} from "@/models/api/pharma-cms-content-page/ColorRestDtoModel";
import {BackgroundImageRestDtoModel} from "@/models/BackgroundImageRestDtoModel";

export class ContentPageRowRestDtoModel implements ContentPageRowRestDto {
    background_color_code: BackgroundColorEnum;
    background_color?: ColorRestDtoModel;
    background_image?: BackgroundImageRestDto;
    border_radius: BorderRadiusRestDtoModel;
    device_visibility?: DeviceVisibilityRestDto;
    container_type_code: ContainerTypeEnum;
    horizontal_gutter_code: HorizontalGutterEnum;
    items_horizontal_alignment_code: ItemsHorizontalAlignmentEnum;
    items_vertical_alignment_code: ItemsVerticalAlignmentEnum;
    margin_bottom_code: MarginBottomEnum;
    margin_top_code: MarginTopEnum;
    padding_bottom_code: PaddingBottomEnum;
    padding_top_code: PaddingTopEnum;

    constructor(background_color_code: BackgroundColorEnum, container_type_code: ContainerTypeEnum, horizontal_gutter_code: HorizontalGutterEnum, items_horizontal_alignment_code: ItemsHorizontalAlignmentEnum, items_vertical_alignment_code: ItemsVerticalAlignmentEnum, margin_bottom_code: MarginBottomEnum, margin_top_code: MarginTopEnum, padding_bottom_code: PaddingBottomEnum, padding_top_code: PaddingTopEnum) {
        this.background_color_code = background_color_code;
        this.container_type_code = container_type_code;
        this.horizontal_gutter_code = horizontal_gutter_code;
        this.items_horizontal_alignment_code = items_horizontal_alignment_code;
        this.items_vertical_alignment_code = items_vertical_alignment_code;
        this.margin_bottom_code = margin_bottom_code;
        this.margin_top_code = margin_top_code;
        this.padding_bottom_code = padding_bottom_code;
        this.padding_top_code = padding_top_code;
        this.border_radius = BorderRadiusRestDtoModel.createWithDefaults();
    }

    static createWithDefaults(): ContentPageRowRestDtoModel {
        return new this(
            BackgroundColorEnum.TRANSPARENT,
            ContainerTypeEnum.CONTAINER,
            HorizontalGutterEnum.G_3,
            ItemsHorizontalAlignmentEnum.START,
            ItemsVerticalAlignmentEnum.TOP,
            MarginBottomEnum.M_3,
            MarginTopEnum.M_3,
            PaddingBottomEnum.P_3,
            PaddingTopEnum.P_3,
        );
    }

    public static cloneFrom(source: ContentPageRowRestDtoModel): ContentPageRowRestDtoModel {
        const result = new this(
            source.background_color_code,
            source.container_type_code,
            source.horizontal_gutter_code,
            source.items_horizontal_alignment_code,
            source.items_vertical_alignment_code,
            source.margin_bottom_code,
            source.margin_top_code,
            source.padding_bottom_code,
            source.padding_top_code
        );
        if (source.border_radius !== null && source.border_radius !== undefined) {
            result.border_radius = BorderRadiusRestDtoModel.cloneFrom(source.border_radius);
        }
        if (source.background_color !== null && source.background_color !== undefined) {
            result.background_color = ColorRestDtoModel.cloneFrom(source.background_color);
        }

        if (source.background_image !== null && source.background_image !== undefined) {
            result.background_image = BackgroundImageRestDtoModel.createWithDefaults();
            cloneBackgroundImageRestDtoMetaData(result.background_image, source.background_image);
        }

        return result;
    }

    public cloneMetadataFrom(source: ContentPageRowRestDtoModel): void {
        cloneContentPageRowRestDtoMetaData(this, source);
    }
}
