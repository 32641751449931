
import {defineComponent} from "vue";
import {CodeDescriptionRestDto, CustomerProductRestDto,} from "@/api/pharma-cpc-product-mgmt/models";
import {CodeDescriptionRestDto as CmsCodeDescriptionRestDto} from "@/api/pharma-cms-pharmacy/models";
import {mapActions, mapGetters} from "vuex";
import SelectCategories from "@/components/layouts/catalog/SelectCategories.vue";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {EnumClassNameCpcEnum} from "@/models/enum/EnumClassNameCpcEnum";
import {
  MultilingualTextFieldRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/MultilingualTextFieldRestDtoModel";
import {StatusEnum} from "@/api/enums/status-enum";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "CustomerProductProduct",
  components: {InputTextEditor, SelectCategories},
  emits: ["submit"],
  props: {
    mode: {
      type: String,
      required: true,
      validate(value: string) {
        return value.toLowerCase() === 'new' || value.toLowerCase() === 'edit';
      }
    },
    isSubmitting: {
      type: Boolean,
      required: true,
    },
    returnRoute: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    docType: 'PRODUCT_IMG' as string,
    imageKey: null as string | null,
    isReady: false as boolean,
  }),
  mounted() {
    this.setImageKey(this.getCustomerProduct.main_image_key.nl);
    this.reloadContent();
  },
  computed: {
    ...mapState(useCustomerStore, ["getAllTrademarksCustomer"]),
    ...mapGetters('cpc_enum', ['getEnumByClassname']),
    ...mapGetters('cpc_product_mgmt', ['getCustomerProduct']),
    usageTypeOptions(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCpcEnum.PRODUCT_USAGE_TYPE);
    },
    existingDocumentKey(): string | null {
      if (this.mode === 'new') {
        return null;
      } else {
        return this.product.main_image_key?.nl ?? null;
      }
    },
    product(): CustomerProductRestDto {
      return this.getCustomerProduct;
    },
    statusOptions(): CodeDescriptionRestDto[] {
      return [
        {code: StatusEnum.PUBLISHED, description: this.$t('published')},
        {code: StatusEnum.NOT_PUBLISHED, description: this.$t('notPublished')},
      ] as CodeDescriptionRestDto[];
    },
    trademarkOptions(): CmsCodeDescriptionRestDto[] {
      return this.getAllTrademarksCustomer();
    },
  },
  methods: {
    ...mapActions('cpc_enum', ["findManyEnumByClassname"]),
    ...mapActions('cpc_product_mgmt', [
      'addProductCategory',
      'removeProductCategory',
    ]),
    async reloadContent(): Promise<void> {
      const enumPayload = {
        classNames: [
          EnumClassNameCpcEnum.PRODUCT_USAGE_TYPE,
        ],
      };

      await Promise.all([
        this.findManyEnumByClassname(enumPayload),
      ]);
      this.isReady=true;
    },
    addCategory(category_code: string) {
      this.addProductCategory(category_code);
    },
    removeCategory(category_code: string) {
      this.removeProductCategory(category_code);
    },
    setImageKey(document_key: string | null) {
      this.imageKey = document_key;
    },
    async submitData() {



      let mainImageKey = this.product.main_image_key;
      if (!mainImageKey) {
        mainImageKey = MultilingualTextFieldRestDtoModel.createWithDefaults();
      }
      mainImageKey.nl = this.imageKey;

      this.product.main_image_key = mainImageKey;
      this.$emit('submit');
    }
  },
});
