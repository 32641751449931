
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import BasePlaceholderTable from "@/components/UI/Bootstrap/BasePlaceholderTable.vue";
import CatalogProductsOverviewTableItem
  from "@/components/layouts/catalog/products/CatalogProductsOverviewTableItem.vue";
import {
  PagedCatalogProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/PagedCatalogProductOverviewResultsRestDtoModel";

export default defineComponent({
  name: "CatalogProductsOverviewTable",
  components: {CatalogProductsOverviewTableItem, BasePlaceholderTable},
  props: {
    productsOverview: {
      type: [PagedCatalogProductOverviewResultsRestDtoModel, Object],
      required: true
    },
    hasProducts: {
      type: Boolean,
      required: true
    },
    ui: {
      type: UIStateDto,
      required: true
    }
  }
})
