
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {CodeDescriptionRestDto, DeliveryCostRuleOverviewRestDto} from "@/api/pharma-order-settings/models";
import {DeliveryMethodsRestService} from "@/services/rest/settings/DeliveryMethodsRestService";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import {useToast} from "vue-toastification";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";
import {EnumClassNameOrderEnum} from "@/models/enum/EnumClassNameOrderEnum";
import {mapMutations} from "vuex";
import {AbilityContext} from "@/context/AbilityContext";
import {usePharmacyStore} from "@/stores/PharmacyStore";
import {Tab} from "bootstrap";
import {Exception} from "@/api/interfaces";

export default defineComponent({
  name: "DeliveryMethodTexts",
  components: {
    BaseContainer,
    BaseTitle,
    AlertError2,
  },
  computed: {
    AbilityContext() {
      return AbilityContext;
    }
  },
  setup() {
    const pharmacyStore = usePharmacyStore();

    return {
      pharmacyStore,
    };
  },
  data() {
    return {
      activeTab: '' as string,
      deliveryMethods: [] as CodeDescriptionRestDto[],
      allDeliveryCostRules: [] as DeliveryCostRuleOverviewRestDto[],
      deliveryCostRulesForDeliveryMethod: [] as DeliveryCostRuleOverviewRestDto[],

      isReady: false as boolean,
      exceptions: [] as Exception[],

      toaster: useToast(),
      pageUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();
    this.pageUI.setReady();
  },
  methods: {
    ...mapMutations('order_enum', ['setEnumerationOverviewModel']),
    async reloadContent(): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        this.allDeliveryCostRules = await DeliveryMethodsRestService.getInstance().findDeliveryCostRules();

        for (const rule of this.allDeliveryCostRules) {
          let isExistingDeliveryMethod = false;
          for (const method of this.deliveryMethods) {
            if (method.code === rule.delivery_method.code) {
              isExistingDeliveryMethod = true;
            }
          }
          if (! isExistingDeliveryMethod) {
            this.deliveryMethods.push(rule.delivery_method);
          }
        }

        const selectedDeliveryMethodTypeCode = this.pharmacyStore.selectedDeliveryMethodCode;
        this.activeTab = selectedDeliveryMethodTypeCode ?? this.deliveryMethods[0]?.code ?? '';
        this.showTab(this.activeTab);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    goToDetail(id: string): object {
      return {
        name: 'delivery-cost-rule-edit',
        params: {
          id: id,
        }
      };
    },
    async deleteDeliveryCostRule(id: string) {
      try {
        const answer = window.confirm(this.$t('deleteConfirmation'));

        if (answer) {
          this.isReady = false;
          this.exceptions = [];

          await DeliveryMethodsRestService.getInstance().deleteDeliveryCostRule(id);
          await this.reloadContent();
        } else {
          return;
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    },
    showTab(code: string) {
      this.pharmacyStore.selectedDeliveryMethodCode = code;
      this.activeTab = code;

      // Filter the rules to the selected tab
      this.deliveryCostRulesForDeliveryMethod = [] as DeliveryCostRuleOverviewRestDto[];
      for (const obj of this.allDeliveryCostRules) {
        if (obj.delivery_method.code === code) {
          this.deliveryCostRulesForDeliveryMethod.push(obj);
        }
      }
    },
  }
})
