import {RestService} from "@/services/rest/RestService";
import {PharmacySWSyncSettingsAPIApi} from "@/api/pharma-cpc-mgmt";
import {PharmacySwSyncTaskOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";

export class PharmacySwSyncSettingsRestService extends RestService {

    private apiService: PharmacySWSyncSettingsAPIApi;

    protected constructor() {
        super();

        this.apiService = new PharmacySWSyncSettingsAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
    }

    public async findPharmacySwSyncTaskHistory(): Promise<PharmacySwSyncTaskOverviewRestDto[]> {
        const response = await this.apiService.getPharmacySwSyncTaskHistory();

        return (response.data.results) ?? [];
    }
}
