import {ColorRestDto} from "@/api/pharma-cms-content-page/models";
import {RgbRestDtoModel} from "@/models/api/pharma-cms-content-page/RgbRestDtoModel";

export class ColorRestDtoModel implements ColorRestDto {
    opacity: number;
    rgb: RgbRestDtoModel;

    constructor(opacity: number, rgb: RgbRestDtoModel) {
        this.opacity = opacity;
        this.rgb = rgb;
    }

    public static createWithDefaults(): ColorRestDtoModel {
        return new this(
            1,
            RgbRestDtoModel.createWithDefaults()
        );
    }

    public static cloneFrom(source: ColorRestDtoModel): ColorRestDtoModel {
        return new this(
            source.opacity,
            RgbRestDtoModel.cloneFrom(source.rgb)
        );
    }

}
