import {ColorRestDto} from "@/api/pharma-cms-content-block/models";
import {RgbRestDtoModel} from "@/models/api/pharma-cms-content-block/RgbRestDtoModel";

export class ColorRestDtoModel implements ColorRestDto {
    opacity: number;
    rgb: RgbRestDtoModel;

    constructor(opacity: number, rgb: RgbRestDtoModel) {
        this.opacity = opacity;
        this.rgb = rgb;
    }

    public static createWithDefaults(): ColorRestDtoModel {
        return new this(
            1,
            RgbRestDtoModel.createWithDefaults()
        );
    }
}
