
import {defineComponent} from 'vue'
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import Lightbox from "@/components/UI/Lightbox.vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {UtilityHelper} from "@/helpers/UtilityHelper";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {PimLaboProductRestService} from "@/services/rest/pim-labo/PimLaboProductRestService";
import {LaboProductDetailRestDtoModel} from "@/models/api/pharma-pim-labo/LaboProductDetailRestDtoModel";
import PimProductsOverviewItemIcons from "@/components/layouts/pim/PimProductsOverviewItemIcons.vue";
import PimProductsOverviewLegend from "@/components/layouts/pim/PimProductsOverviewLegend.vue";
import NamedTextFragmentPanel from "@/components/layouts/pim/mgmt/NamedTextFragmentPanel.vue";
import PimFeedbackPanel from "@/components/layouts/pim/mgmt/PimFeedbackPanel.vue";
import {useToast} from "vue-toastification";
import {Tooltip} from "bootstrap";
import {AbilityContext} from "@/context/AbilityContext";
import {EnumClassNamePimEnum} from "@/models/enum/EnumClassNamePimEnum";
import {NamedTextFragmentRestDtoModel} from "@/models/api/pharma-pim-mgmt/NamedTextFragmentRestDtoModel";
import {PimMgmtRestService} from "@/services/rest/pim-mgmt/PimMgmtRestService";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {PimFeedbackRestDtoModel} from "@/models/api/pharma-pim-mgmt/PimFeedbackRestDtoModel";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";
import {mapActions, mapGetters} from "vuex";
import {FullProductInfoRestDtoModel} from "@/models/api/pharma-pim-mgmt/FullProductInfoRestDtoModel";
import {ProductDetailsCoreRestDtoModel} from "@/models/api/pharma-pim-mgmt/ProductDetailsCoreRestDtoModel";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";

export default defineComponent({
  name: "PimLabProductsDetail",
  components: {
    PimFeedbackPanel, NamedTextFragmentPanel,
    PimProductsOverviewLegend, PimProductsOverviewItemIcons,
    Lightbox,
    BaseCardBody,
    BaseCardHeader,
    BaseCard,
    BaseSpinner,
    AlertError2,
  },
  props: {
    cnkCode: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      product: LaboProductDetailRestDtoModel.createWithDefaults(),
      productInfoNl: FullProductInfoRestDtoModel.createWithDefaults(),
      isCorePimDetailsEditMode: false,
      corePimDetailsDto: null as ProductDetailsCoreRestDtoModel | null,

      isEditingNamedTextFragmentWithId: null as string | null,
      isShowFeedbackForFragmentWithId: null as string | null,
      isNewNamedTextFragmentPanelOpen: false,
      isNewTextFragmentPanelOpen: false,
      isShowGeneralFeedback: false,

      toaster: useToast(),
      tooltips: [] as Tooltip[],

      productUI: UIStateDto.createWithDefaults()
    }
  },
  watch: {
    cnkCode() {
      this.reloadContent();
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('pim_enum', ['getEnumByClassname']),
    AbilityContext() {
      return AbilityContext
    },
    UtilityHelper() {
      return UtilityHelper
    },
    trademarkOptions(): CodeDescriptionRestDto[] {
      if (this.isCorePimDetailsEditMode) {
        return this.getEnumByClassname(EnumClassNamePimEnum.PIM_TRADEMARK) as CodeDescriptionRestDto[];
      } else {
        return [];
      }
    },
    targetGroupOptions(): CodeDescriptionRestDto[] {
      if (this.isCorePimDetailsEditMode) {
        return this.getEnumByClassname(EnumClassNamePimEnum.PIM_TARGET_GROUP) as CodeDescriptionRestDto[];
      } else {
        return [];
      }
    },
    unitOptions(): CodeDescriptionRestDto[] {
      if (this.isCorePimDetailsEditMode) {
        return this.getEnumByClassname(EnumClassNamePimEnum.PIM_UNIT) as CodeDescriptionRestDto[];
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions('pim_enum', ["findManyEnumByClassname"]),
    async reloadContent(): Promise<void> {
      this.productUI
        .setNotReady()
        .clearError();

      this.isCorePimDetailsEditMode = false;

      try {
        await Promise.all([
          this.product = await PimLaboProductRestService.getInstance()
            .findLaboProductForCnkCode(this.cnkCode),
          this.findManyEnumByClassname([
            EnumClassNamePimEnum.TEXT_FRAGMENT_NAME,
            EnumClassNamePimEnum.PIM_PRODUCT_FIELD,
          ]),
          await this.reloadPimContent(),
        ]);
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.productUI.setReady();
      }
    },
    async submitNamedFragmentContent(fragmentId: string | null, namedFragment: NamedTextFragmentRestDtoModel): Promise<void> {
      this.productUI.clearError();

      try {
        if (fragmentId !== null) {
          await PimMgmtRestService.getInstance().updateNamedTextFragment(fragmentId, namedFragment);
        } else {
          await PimMgmtRestService.getInstance().createNamedTextFragment(this.cnkCode, 'nl', namedFragment);
          this.isNewNamedTextFragmentPanelOpen = false;
        }
        await this.reloadPimContent();

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async submitNamedFragmentCancel(fragmentId: string | null): Promise<void> {
      this.isNewNamedTextFragmentPanelOpen = false;
      if (fragmentId !== null) {
        this.toggleIsEditingNamedTextFragmentWithId(fragmentId);
      }
    },
    async submitPimFeedback(feedback: PimFeedbackRestDtoModel): Promise<void> {
      this.productUI.clearError();

      try {
        await PimMgmtRestService.getInstance().createPimFeedback(this.cnkCode, 'nl', feedback);
        if (feedback.text_fragment_id != null) {
          this.toggleIsShowFeedbackForFragmentWithId(feedback.text_fragment_id);
        } else {
          this.isShowGeneralFeedback = false
        }

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async submitPimFeedbackCancel(fragmentId: string | null): Promise<void> {
      this.isShowGeneralFeedback = false
      if (fragmentId !== null) {
        this.toggleIsShowFeedbackForFragmentWithId(fragmentId);
      }
    },
    async toggleEditCorePimDetails(): Promise<void> {
      if (!this.isCorePimDetailsEditMode) {
        await Promise.all([
          this.corePimDetailsDto = ProductDetailsCoreRestDtoModel.createFromProductDetailsRestDto(this.productInfoNl?.details),
          this.findManyEnumByClassname([EnumClassNamePimEnum.PIM_TRADEMARK, EnumClassNamePimEnum.PIM_TARGET_GROUP, EnumClassNamePimEnum.PIM_UNIT, ]),
        ]);
      }
      this.isCorePimDetailsEditMode = !this.isCorePimDetailsEditMode;
    },
    async submitPimCoreData(): Promise<void> {
      try {
        await PimMgmtRestService.getInstance().updatePimDetailsCore(this.corePimDetailsDto, this.product.product_code);
        this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }

    },
    openNewNamedTextFragmentPanel(): void {
      this.isNewNamedTextFragmentPanelOpen = true;
    },
    toggleIsEditingNamedTextFragmentWithId(fragmentId: string): void {
      this.isShowFeedbackForFragmentWithId = null;
      this.isNewNamedTextFragmentPanelOpen = false;
      if (this.isEditingNamedTextFragmentWithId === fragmentId) {
        this.isEditingNamedTextFragmentWithId = null;
      } else {
        this.isEditingNamedTextFragmentWithId = fragmentId;
      }
    },
    toggleIsShowFeedbackForFragmentWithId(fragmentId: string): void {
      this.isEditingNamedTextFragmentWithId = null;
      this.isNewNamedTextFragmentPanelOpen = false;
      if (this.isShowFeedbackForFragmentWithId === fragmentId) {
        this.isShowFeedbackForFragmentWithId = null;
      } else {
        this.isShowFeedbackForFragmentWithId = fragmentId;
      }
    },
    async deleteNamedTextFragmentWithId(fragmentId: string): Promise<void> {
      this.isEditingNamedTextFragmentWithId = null;
      try {
        await PimMgmtRestService.getInstance().deleteNamedTextFragment(fragmentId);
        await this.reloadPimContent();

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    initializeTooltips(): void {
      document.querySelectorAll('#pim-products-detail [data-bs-toggle="tooltip"]').forEach(el => {
        const tooltip = new Tooltip(el as Element);
        this.tooltips.push(tooltip);
      });
    },
    hideTooltips(): void {
      this.tooltips.forEach(tt => {
        tt.hide();
      });
    },
    confirmDeleteFragment(fragmentId: string): void {
      const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
        title: this.$t('cms.delete'),
        body: this.$t('cms.confirmDeleteTextFragment'),
        confirmButtonText: this.$t('delete'),
        confirmButtonClass: 'btn-danger',
      });

      onConfirm(async () => {
        await this.deleteNamedTextFragmentWithId(fragmentId);

        this.hideTooltips();
        this.toaster.success(this.$t('cms.successfullyDeleted'));

        await this.reloadContent();
      });

      reveal();
    },
    async reloadPimContent(): Promise<void> {
      this.productInfoNl = await PimMgmtRestService.getInstance()
        .findMgmtProductInfo(this.cnkCode, 'nl');
      this.isEditingNamedTextFragmentWithId = null;
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
})
